<template>
    <div class="home">
        <div data-elementor-type="wp-post" data-elementor-id="172600" data-post-id="172600" data-obj-id="172600" class="elementor elementor-172600 dce-elementor-post-172600" data-elementor-settings="[]">
            <div class="elementor-inner">
                <div class="elementor-section-wrap">
                    <!-- ADJUST BACKGROUND IMAGE -->
                    <section
                        data-dce-background-overlay-color="#00000000"
                        class="elementor-section elementor-top-section elementor-element elementor-element-513f728 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                        data-id="513f728"
                        data-element_type="section"
                        id="s0"
                        v-bind:style="{ 
                            'background-image': 'url(https://admin.itsyourdayofficial.com/foto_cover/'+client.foto_cover+')',
                            'background-size': 'cover',
                            'background-position': 'center',
                            'display': 'flex',
                            'justify-content': 'center',
                            'align-items': 'center',
                        }"
                        data-settings='{
                            "background_background":"slideshow",
                            "background_slideshow_slide_duration":500,
                            "sticky":"top",
                            "background_slideshow_loop":"yes",
                            "background_slideshow_slide_transition":"fade",
                            "background_slideshow_transition_duration":500,
                            "_ha_eqh_enable":false,
                            "sticky_on":["desktop","tablet","mobile"],
                            "sticky_offset":0,
                            "sticky_effects_offset":0
                        }'
                    >
                        <div class="elementor-background-overlay" style="background-color:#000000; opacity: 0.7"></div>
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div
                                    data-dce-background-overlay-color="#00000000"
                                    class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9da6ff9 wdp-sticky-section-no"
                                    data-id="9da6ff9"
                                    data-element_type="column"
                                    data-settings='{"background_background":"classic"}'
                                >
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-background-overlay"></div>
                                        <div class="elementor-widget-wrap">
                                            <div
                                                class="elementor-element elementor-element-7d4475a animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="7d4475a"
                                                data-element_type="widget"
                                                data-settings='{"_animation":"fadeInDown","_animation_delay":400}'
                                                data-widget_type="heading.default"
                                            >
                                                <div class="elementor-widget-container">
                                                    <p class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive; ">{{ config.language === 1 ? 'Dear,' : 'Kepada,' }}</p>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-7d4475a animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="7d4475a"
                                                data-element_type="widget"
                                                data-settings='{"_animation":"fadeInDown","_animation_delay":400}'
                                                data-widget_type="heading.default"
                                            >
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default" style="font-size:20px; color:#79a8a9">
                                                        {{guest.nama}}
                                                    </h2>
                                                    <h2 v-if="decodedValue" class="elementor-heading-title elementor-size-default" style="font-size:20px; color:#79a8a9">
                                                        {{ decodedValue }}
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-0879335 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="0879335"
                                                data-element_type="widget"
                                                data-settings='{"_animation":"fadeInDown","_animation_delay":400}'
                                                data-widget_type="heading.default"
                                                style="padding-bottom:300px;"
                                            >
                                                <div class="elementor-widget-container">
                                                    <p class="elementor-heading-title elementor-size-default">{{ config.language === 1 ? 'You Are Invited!' : 'Mohon maaf bila ada kesalahan pada penulisan nama/gelar' }}</p>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-600b5bc elementor-headline--style-rotate animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-animated-headline"
                                                data-id="600b5bc"
                                                data-element_type="widget"
                                                data-settings='{"headline_style":"rotate","animation_type":"slide","rotating_text":"THE\nWEDDING\nOF","rotate_iteration_delay":1500,"_animation":"fadeInUp","loop":"yes"}'
                                                data-widget_type="animated-headline.default"
                                                style="position: initial"
                                            >
                                                <div class="elementor-widget-container">
                                                    <h3 class="elementor-headline elementor-headline-animation-type-slide">
                                                        <span class="elementor-headline-dynamic-wrapper elementor-headline-text-wrapper">
                                                            <span class="elementor-headline-dynamic-text elementor-headline-text-active" style="color:#aacfd0">THE </span>
                                                            <span class="elementor-headline-dynamic-text"  style="color:#aacfd0">WEDDING </span>
                                                            <span class="elementor-headline-dynamic-text"  style="color:#aacfd0">OF </span>
                                                        </span>
                                                    </h3>
                                                </div>                                                
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-c55fbb0 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="c55fbb0"
                                                data-element_type="widget"
                                                data-settings='{"_animation":"fadeInDown"}'
                                                data-widget_type="heading.default"
                                            >   
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.hajat == 'wanita'" style="font-family: 'Italiana', Sans-serif;">
                                                        {{ client.nama_wanita }} <span style="font-family: Analogue, Times, serif !important; font-size: 25px; font-style: italic;">&</span>
                                                        {{ client.nama_pria }}
                                                    </h2>
                                                    <h2 class="elementor-heading-title elementor-size-default" v-else  style="font-family: 'Italiana', Sans-serif;">
                                                        {{ client.nama_pria }} <span style="font-family: Analogue, Times, serif !important; font-size: 25px; font-style: italic;">&</span>
                                                        {{ client.nama_wanita }} 
                                                    </h2>
                                                </div>
                                            </div>
                                            <!-- <div
                                                class="elementor-element elementor-element-7d4475a animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="7d4475a"
                                                data-element_type="widget"
                                                data-settings='{"_animation":"fadeInDown","_animation_delay":400}'
                                                data-widget_type="heading.default"
                                            >
                                                <div class="elementor-widget-container">
                                                    <p class="elementor-heading-title elementor-size-default">{{ config.language === 1 ? 'Dear' : 'Kepada' }}</p>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-7d4475a animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="7d4475a"
                                                data-element_type="widget"
                                                data-settings='{"_animation":"fadeInDown","_animation_delay":400}'
                                                data-widget_type="heading.default"
                                            >
                                                <div class="elementor-widget-container">
                                                    <h2 class="elementor-heading-title elementor-size-default" style="font-size:20px;">
                                                        {{guest.nama}}
                                                    </h2>
                                                    <h2 v-if="decodedValue" class="elementor-heading-title elementor-size-default" style="font-size:20px;">
                                                        {{ decodedValue }}
                                                    </h2>
                                                </div>
                                            </div>
                                            <div
                                                class="elementor-element elementor-element-0879335 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                data-id="0879335"
                                                data-element_type="widget"
                                                data-settings='{"_animation":"fadeInDown","_animation_delay":400}'
                                                data-widget_type="heading.default"
                                            >
                                                <div class="elementor-widget-container">
                                                    <p class="elementor-heading-title elementor-size-default">{{ config.language === 1 ? 'You Are Invited!' : 'Mohon maaf bila ada kesalahan pada penulisan nama/gelar' }}</p>
                                                </div>
                                            </div> -->
                                            <div
                                                class="elementor-element elementor-element-447df93 elementor-align-center animated-slow elementor-widget__width-auto wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-button"
                                                data-id="447df93"
                                                data-element_type="widget"
                                                id="b_o"
                                                data-settings='{"_animation":"fadeInUp","_animation_delay":1200}'
                                                data-widget_type="button.default"
                                                style="padding-bottom:70px;"
                                            >
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-button-wrapper">
                                                        <a class="elementor-button elementor-size-sm elementor-animation-shrink" role="button" style="padding:15px 15px 10px 15px;">
                                                            <span class="elementor-button-content-wrapper">
                                                                <span class="elementor-button-icon elementor-align-icon-left">
                                                                    <i class="lni lni-envelope"></i>
                                                                </span>
                                                                <span class="elementor-button-text">{{ config.language === 1 ? 'Open Invitation' : 'Buka Undangan' }}</span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-34a8077 animated-slow elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no elementor-invisible"
                        data-id="34a8077"
                        data-element_type="section"
                        id="content"
                        data-settings='{"animation":"fadeIn","_ha_eqh_enable":false}'
                    >
                        <div class="elementor-background-overlay"></div>
                        <div class="elementor-container elementor-column-gap-no">
                            <div class="elementor-row">
                                <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b5d8cb8 wdp-sticky-section-no" data-id="b5d8cb8" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <!-- ADJUST BACKGROUND IMAGE -->
                                            <section
                                                data-dce-background-overlay-color="#A99D87"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-4a0a9f7 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default wdp-sticky-section-no"
                                                data-id="4a0a9f7"
                                                data-element_type="section"
                                                id="cover"
                                                v-bind:style="{ 
                                                    'background-image': 'url(https://admin.itsyourdayofficial.com/foto_cover/'+client.foto_cover+')',
                                                    'background-size': 'cover',
                                                    'background-position': 'center',
                                                    'display': 'flex',
                                                    'justify-content': 'center',
                                                    'align-items': 'center',
                                                }"

                                                
                                                data-settings='{
                                                    "background_background":"slideshow",
                                                    "background_slideshow_slide_duration":1000,
                                                    "background_slideshow_transition_duration":12000,
                                                    "background_slideshow_slide_transition":"slide_left",
                                                    "background_slideshow_loop":"yes",
                                                    "_ha_eqh_enable":false
                                                }'
                                            >
                                                <div class="elementor-background-overlay"></div>
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div
                                                            class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-3967eea wdp-sticky-section-no"
                                                            data-id="3967eea"
                                                            data-element_type="column"
                                                            data-settings='{"background_background":"classic"}'
                                                        >
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">

                                                                    <!-- SECTION UP -->
                                                                    <section
                                                                        class="elementor-section elementor-inner-section elementor-element elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                        data-element_type="section"
                                                                        data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                                                        style="display: flex; align-items: center; justify-content: center; height: 130vh;"
                                                                    >
                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                            <div class="elementor-row">

                                                                                <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-42b2cd7 wdp-sticky-section-no" data-id="42b2cd7" data-element_type="column">
                                                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                                                        <div class="elementor-widget-wrap">
                                                                               
                                                                                            <div class="elementor-widget-container" style="margin-bottom: 20px;">
                                                                                                <h2 class="elementor-heading-title elementor-size-default" 
                                                                                                style="margin: 0; color: #79a8a9;
                                                                                                    font-family: Arima Madurai, Sans-serif;
                                                                                                    font-size: 18px;
                                                                                                    font-weight: bold;
                                                                                                    letter-spacing: 2px;">THE WEDDING OF</h2>
                                                                                            </div>

                                                                                            <div class="elementor-widget-container">
                                                                                                <h2 class="elementor-heading-title elementor-size-default" v-if="client.hajat == 'wanita'" 
                                                                                                        style="color: #aacfd0;
                                                                                                        font-family: 'Italiana', Sans-serif;
                                                                                                        font-size: 40px;
                                                                                                        font-weight: normal;
                                                                                                        letter-spacing: 1px;">
                                                                                                    {{ client.nama_wanita }} <span style="font-family: Analogue, Times, serif !important; font-size: 25px; font-style: italic;">&</span>
                                                                                                    {{ client.nama_pria }}
                                                                                                </h2>

                                                                                                <h2 class="elementor-heading-title elementor-size-default" v-else
                                                                                                        style="color: #aacfd0;
                                                                                                        font-family: 'Italiana', Sans-serif;
                                                                                                        font-size: 40px;
                                                                                                        font-weight: normal;
                                                                                                        letter-spacing: 1px;">
                                                                                                    {{ client.nama_pria }} <span style="font-family: Analogue, Times, serif !important; font-size: 25px; font-style: italic;">&</span>
                                                                                                    {{ client.nama_wanita }} 
                                                                                                </h2>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>

                                                                    <section
                                                                        data-dce-background-color="#00000025"
                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-6389dc0 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                        data-id="6389dc0"
                                                                        data-element_type="section"
                                                                        data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                                                        style="border-radius: 20px 20px 20px 20px;"
                                                                    >
                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                            <div class="elementor-row">
                                                                                <div
                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-5d3b925 wdp-sticky-section-no"
                                                                                    data-id="5d3b925"
                                                                                    data-element_type="column"
                                                                                >
                                                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                                                        <div class="elementor-widget-wrap">
                                                                                            <section
                                                                                                class="elementor-section elementor-inner-section elementor-element elementor-element-2f6a1d9 animated-slow elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no elementor-invisible"
                                                                                                data-id="2f6a1d9"
                                                                                                data-element_type="section"
                                                                                                data-settings='{"animation":"fadeInUp","_ha_eqh_enable":false}'
                                                                                                style="margin-top: 0em;"
                                                                                            >
                                                                                                <div class="elementor-container elementor-column-gap-default">
                                                                                                    <div class="elementor-row">
                                                                                                        <div
                                                                                                            class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-efb4fa5 wdp-sticky-section-no"
                                                                                                            data-id="efb4fa5"
                                                                                                            data-element_type="column"
                                                                                                        >
                                                                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                                                                <div class="elementor-widget-wrap">
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-f25e752 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                                        data-id="f25e752"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"fadeInDown"}'
                                                                                                                        data-widget_type="heading.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <h2 class="elementor-heading-title elementor-size-default">SAVE THE DATE</h2>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-2a6b54b elementor-countdown--label-inline animated-slow wdp-sticky-section-no elementor-widget elementor-widget-countdown"
                                                                                                                        data-id="2a6b54b"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"none"}'
                                                                                                                        data-widget_type="countdown.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <div class="elementor-countdown-wrapper" data-date="1682730000">
                                                                                                                                <div class="elementor-countdown-item">
                                                                                                                                    <span class="elementor-countdown-label"
                                                                                                                                        style="color: #aacfd0;
                                                                                                                                            font-family: Arima Madurai, Sans-serif;
                                                                                                                                            font-size: 11px;
                                                                                                                                            font-weight: 400;"
                                                                                                                                    
                                                                                                                                    >{{days}} {{ config.language === 1 ? 'Days' : 'Hari' }}</span>
                                                                                                                                </div>
                                                                                                                                <div class="elementor-countdown-item">
                                                                                                                                    <span class="elementor-countdown-label"
                                                                                                                                    style="color: #aacfd0;
                                                                                                                                            font-family: Arima Madurai, Sans-serif;
                                                                                                                                            font-size: 11px;
                                                                                                                                            font-weight: 400;"
                                                                                                                                    >{{hours}} {{ config.language === 1 ? 'Hours' : 'Jam' }}</span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-b052f3c wdp-sticky-section-no"
                                                                                                            data-id="b052f3c"
                                                                                                            data-element_type="column"
                                                                                                        >
                                                                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                                                                <div class="elementor-widget-wrap">
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-2a6b54b elementor-countdown--label-inline animated-slow wdp-sticky-section-no elementor-widget elementor-widget-countdown"
                                                                                                                        data-id="2a6b54b"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"none"}'
                                                                                                                        data-widget_type="countdown.default"
                                                                                                                        style="margin-bottom: 10px;"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <h2 
                                                                                                                                style="
                                                                                                                                    color: #79a8a9;
                                                                                                                                    font-family: Arima Madurai, Sans-serif;
                                                                                                                                    font-size: 12px;
                                                                                                                                    font-weight: normal;
                                                                                                                                    font-style: italic;
                                                                                                                                    letter-spacing: 2px;"
                                                                                                                                v-if="config.countdown === 1" class="elementor-heading-title elementor-size-default">
                                                                                                                                {{ moment (client.tgl_resepsi).locale(config.language === 1 ? 'en' : 'id').format('dddd, DD MMMM YYYY') }}
                                                                                                                            </h2>
                                                                                                                            <h2 style="
                                                                                                                                    color: #79a8a9;
                                                                                                                                    font-family: Arima Madurai, Sans-serif;
                                                                                                                                    font-size: 12px;
                                                                                                                                    font-weight: normal;
                                                                                                                                    font-style: italic;
                                                                                                                                    letter-spacing: 2px;"
                                                                                                                                     v-if="config.countdown !== 1" class="elementor-heading-title elementor-size-default"> 
                                                                                                                                {{ moment (client.tgl_akad).locale(config.language === 1 ? 'en' : 'id').format('dddd, DD MMMM YYYY') }}
                                                                                                                            </h2>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-f35ec8d elementor-countdown--label-inline wdp-sticky-section-no elementor-widget elementor-widget-countdown"
                                                                                                                        data-id="f35ec8d"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-widget_type="countdown.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <div class="elementor-countdown-wrapper" data-date="1682730000">
                                                                                                                                <div class="elementor-countdown-item">
                                                                                                                                    <span class="elementor-countdown-label" style="color:#aacfd0">{{minutes}} {{ config.language === 1 ? 'Minutes' : 'Menit' }}</span>
                                                                                                                                </div>
                                                                                                                                <div class="elementor-countdown-item">
                                                                                                                                    <span class="elementor-countdown-label" style="color:#aacfd0">{{seconds}} {{ config.language === 1 ? 'Seconds' : 'Detik' }}</span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </section>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section
                                                data-dce-background-color="#F5F2EC"
                                                data-dce-background-overlay-color="#F5F2EC"
                                                data-dce-background-image-url="assets/wp-content/uploads/2022/08/sample-luxury-2.png"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-663bb43 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                data-id="663bb43"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-background-overlay"></div>
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-35e80ce wdp-sticky-section-no" data-id="35e80ce" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <section
                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-5eb7525 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no elementor-invisible"
                                                                        data-id="5eb7525"
                                                                        data-element_type="section"
                                                                        data-settings='{"animation":"fadeIn","_ha_eqh_enable":false}'
                                                                    >
                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                            <div class="elementor-row">
                                                                                <div
                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-598fcc0 wdp-sticky-section-no"
                                                                                    data-id="598fcc0"
                                                                                    data-element_type="column"
                                                                                >
                                                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                                                        <div class="elementor-widget-wrap">
                                                                                            <div
                                                                                                class="elementor-element elementor-element-13d8394 elementor-widget__width-auto wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                data-id="13d8394"
                                                                                                data-element_type="widget"
                                                                                                data-widget_type="heading.default"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default" style="color: #79a8a9">
                                                                                                        {{
                                                                                                        getFirstCharacter(
                                                                                                            client.hajat === 'wanita' ? client.nama_wanita : client.nama_pria
                                                                                                        )
                                                                                                        }}
                                                                                                    </h2>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-7ddb156 elementor-widget__width-auto wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                data-id="7ddb156"
                                                                                                data-element_type="widget"
                                                                                                data-widget_type="heading.default"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default" style="color:#aacfd0">&</h2>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-9fb2d43 elementor-widget__width-auto wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                data-id="9fb2d43"
                                                                                                data-element_type="widget"
                                                                                                data-widget_type="heading.default"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default" style="color: #79a8a9">
                                                                                                        {{
                                                                                                        getFirstCharacter(
                                                                                                            client.hajat === 'pria' ? client.nama_wanita : client.nama_pria
                                                                                                        )
                                                                                                        }}
                                                                                                    </h2>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                    <div
                                                                        class="elementor-element elementor-element-666d668 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="666d668"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" v-html="client.quote" style="color:#aacfd0">
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-71f739f animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="71f739f"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" style="color:#79a8a9">{{ client.title_quote }}</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section
                                                data-dce-background-color="#f4f7f7"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-184648d elementor-section-height-min-height elementor-section-boxed elementor-section-height-default wdp-sticky-section-no"
                                                data-id="184648d"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-42b2cd7 wdp-sticky-section-no" data-id="42b2cd7" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-5735610 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="5735610"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" style="font-family:'Dancing Script', cursive; font-weight:bolder; font-size: 24px;" >{{ client.hajat === 'wanita' ? 'The Bride' : 'The Groom' }}</h2>
                                                                        </div>
                                                                    </div>
                                                                    <!-- ADJUST BACKGROUND IMAGE -->
                                                                    <section
                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-de45e14 elementor-section-height-min-height animated-slow elementor-section-boxed elementor-section-height-default wdp-sticky-section-no elementor-invisible"
                                                                        data-id="de45e14"
                                                                        data-element_type="section"
                                                                        v-bind:style="{ 
                                                                            'background-image': 'url(' + (client.hajat === 'wanita' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria) + ')',
                                                                            'background-size': '100% auto', /* Tambahkan ini untuk membuat gambar responsif */
                                                                            'background-position': 'center center',
                                                                            'background-repeat': 'no-repeat'
                                                                        }"
                                                                        data-settings='{
                                                                            "background_background":"slideshow",
                                                                            "background_slideshow_slide_duration":1500,
                                                                            "background_slideshow_transition_duration":8000,
                                                                            "background_slideshow_slide_transition":"slide_right",
                                                                            "animation":"fadeInLeft",
                                                                            "background_slideshow_loop":"yes",
                                                                            "_ha_eqh_enable":false
                                                                        }'
                                                                        >

                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                            <div class="elementor-row">
                                                                                <div
                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-a9c483e wdp-sticky-section-no"
                                                                                    data-id="a9c483e"
                                                                                    data-element_type="column"
                                                                                >
                                                                                    <div class="elementor-column-wrap">
                                                                                        <div class="elementor-widget-wrap"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section
                                                data-dce-background-color="#f4f7f7"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-e44369e elementor-section-height-min-height elementor-section-boxed elementor-section-height-default wdp-sticky-section-no"
                                                data-id="e44369e"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-4e05e79 wdp-sticky-section-no" data-id="4e05e79" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-35e168a animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="35e168a"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" >{{ client.hajat === 'wanita' ? client.gelar_wanita : client.gelar_pria }}</h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-44ca2c9 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="44ca2c9"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" v-if="client.hajat === 'pria'">
                                                                                {{ config.language === 1 ? 'Son of' : 'Putra dari' }}
                                                                            </h2>
                                                                            <h2 class="elementor-heading-title elementor-size-default" v-if="client.hajat === 'wanita'">
                                                                                {{ config.language === 1 ? 'Daughter of' : 'Putri dari' }}
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-0156c7b wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="0156c7b"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <!-- <h2 class="elementor-heading-title elementor-size-default">
                                                                                {{
                                                                                    client.hajat === 'wanita' ? client.nama_ayah_wanita + (config.language === 1 ? ' and ' : ' dan ') + client.nama_ibu_wanita : client.nama_ayah_pria + (config.language === 1 ? ' and ' : ' dan ') + client.nama_ibu_pria
                                                                                }}
                                                                            </h2> -->
                                                                            <h2 class="elementor-heading-title elementor-size-default" v-if="client.hajat === 'wanita'">
                                                                                {{ client.nama_ayah_wanita }}
                                                                                <br>
                                                                                {{ (config.language === 1 ? ' and ' : ' dan ') }}
                                                                                <br>
                                                                                {{ client.nama_ibu_wanita }}
                                                                            </h2>
                                                                            <h2 class="elementor-heading-title elementor-size-default" v-else>
                                                                                {{ client.nama_ayah_pria }}
                                                                                <br>
                                                                                {{ (config.language === 1 ? ' and ' : ' dan ') }}
                                                                                <br>
                                                                                {{ client.nama_ibu_pria }}
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section
                                                data-dce-background-color="#f4f7f7"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-02dd2e9 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default wdp-sticky-section-no"
                                                data-id="02dd2e9"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-b4c5371 wdp-sticky-section-no" data-id="b4c5371" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-22b46bb animated-slow elementor-absolute elementor-widget-divider--view-line wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-divider"
                                                                        data-id="22b46bb"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"zoomIn","_position":"absolute"}'
                                                                        data-widget_type="divider.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-divider">
                                                                                <span class="elementor-divider-separator"></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-dbaeade animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="dbaeade"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" style="font-family:'Dancing Script', cursive; font-weight:bolder; font-size: 24px;">{{ client.hajat === 'pria' ? 'The Bride' : 'The Groom' }}</h2>
                                                                        </div>
                                                                    </div>
                                                                    <!-- ADJUST IMAGE BACKGROUND -->
                                                                    <section
                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-de45e14 elementor-section-height-min-height animated-slow elementor-section-boxed elementor-section-height-default wdp-sticky-section-no elementor-invisible"
                                                                        data-id="de45e14"
                                                                        data-element_type="section"
                                                                        v-bind:style="{ 
                                                                            'background-image': 'url(' + (client.hajat === 'pria' ? 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_wanita : 'https://admin.itsyourdayofficial.com/foto_calon/' + client.foto_pria) + ')',
                                                                            'background-size': '100% auto', /* Tambahkan ini untuk membuat gambar responsif */
                                                                            'background-position': 'center center',
                                                                            'background-repeat': 'no-repeat'
                                                                        }"
                                                                        data-settings='{
                                                                            "background_background":"slideshow",
                                                                            "background_slideshow_slide_duration":1500,
                                                                            "background_slideshow_transition_duration":8000,
                                                                            "background_slideshow_slide_transition":"slide_right",
                                                                            "animation":"fadeInLeft",
                                                                            "background_slideshow_loop":"yes",
                                                                            "_ha_eqh_enable":false
                                                                        }'
                                                                        >
                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                            <div class="elementor-row">
                                                                                <div
                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-d776581 wdp-sticky-section-no"
                                                                                    data-id="d776581"
                                                                                    data-element_type="column"
                                                                                >
                                                                                    <div class="elementor-column-wrap">
                                                                                        <div class="elementor-widget-wrap"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section
                                                data-dce-background-color="#f4f7f7"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-62175bc elementor-section-height-min-height elementor-section-boxed elementor-section-height-default wdp-sticky-section-no"
                                                data-id="62175bc"
                                                data-element_type="section"
                                                data-settings='{"background_background":"gradient","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-9bedd01 wdp-sticky-section-no" data-id="9bedd01" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-9dedd2c animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="9dedd2c"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default">{{ client.hajat === 'pria' ? client.gelar_wanita : client.gelar_pria }}</h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-5e0f78b animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="5e0f78b"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" v-if="client.hajat === 'wanita'">
                                                                                {{ config.language === 1 ? 'Son of' : 'Putra dari' }}
                                                                            </h2>
                                                                            <h2 class="elementor-heading-title elementor-size-default" v-if="client.hajat === 'pria'">
                                                                                {{ config.language === 1 ? 'Daughter of' : 'Putri dari' }}
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-1cf51fb wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="1cf51fb"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <!-- <h2 class="elementor-heading-title elementor-size-default">{{
                                                                                    client.hajat === 'pria'
                                                                                        ? client.nama_ayah_wanita + (config.language === 1 ? ' and ' : ' dan ') + client.nama_ibu_wanita
                                                                                        : client.nama_ayah_pria + (config.language === 1 ? ' and ' : ' dan ') + client.nama_ibu_pria
                                                                                }}</h2> -->
                                                                            <h2 class="elementor-heading-title elementor-size-default" v-if="client.hajat === 'pria'">
                                                                                {{ client.nama_ayah_wanita }}
                                                                                <br>
                                                                                {{ (config.language === 1 ? ' and ' : ' dan ') }}
                                                                                <br>
                                                                                {{ client.nama_ibu_wanita }}
                                                                            </h2>
                                                                            <h2 class="elementor-heading-title elementor-size-default" v-else>
                                                                                {{ client.nama_ayah_pria }}
                                                                                <br>
                                                                                {{ (config.language === 1 ? ' and ' : ' dan ') }}
                                                                                <br>
                                                                                {{ client.nama_ibu_pria }}
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section
                                                data-dce-background-color="#A99D87"
                                                data-dce-background-overlay-color="#A99D87"
                                                v-bind:style="{ 
                                                    'background-image': 'url(https://admin.itsyourdayofficial.com/gallery/'+img1.file+')',
                                                    'background-size': 'cover',
                                                    'background-position': 'center',
                                                    'display': 'flex',
                                                    'justify-content': 'center',
                                                    'align-items': 'center',
                                                }"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-5c6df99 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default wdp-sticky-section-no"
                                                data-id="5c6df99"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-background-overlay"></div>
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-59519cc wdp-sticky-section-no" data-id="59519cc" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-d21ab34 elementor-widget__width-auto animated-slow wdp-sticky-section-no elementor-widget elementor-widget-text-path animated fadeIn"
                                                                        data-id="d21ab34"
                                                                        data-element_type="widget"
                                                                        data-settings='{"text":"WEDDING EVENT WEDDING EVENT WEDDING EVENT","align":"center","start_point":{"unit":"%","size":"","sizes":[]},"_animation":"fadeIn","link":{"url":"","is_external":"","nofollow":"","custom_attributes":""}}'
                                                                        data-widget_type="text-path.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <div class="e-text-path" data-text="WEDDING EVENT WEDDING EVENT WEDDING EVENT" data-url="assets/wp-content/plugins/elementor/assets/svg-paths/circle.svg">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="250.5" height="250.5" viewBox="0 0 250.5 250.5">
                                                                                    <path d="M.25,125.25a125,125,0,1,1,125,125,125,125,0,0,1-125-125" id="e-path-d21ab34"></path>
                                                                                    <text>
                                                                                        <textPath id="e-text-path-d21ab34" href="#e-path-d21ab34" startOffset="%">SAVE THE DATE SAVE THE DATE SAVE THE DATE</textPath>
                                                                                    </text>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-ae1120e animated-slow elementor-align-center wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-button"
                                                                        data-id="ae1120e"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="button.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a
                                                                                    :href="generateCalendarLink()"
                                                                                    class="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink"
                                                                                    role="button"
                                                                                    target="_blank"
                                                                                >
                                                                                    <span class="elementor-button-content-wrapper">
                                                                                        <span class="elementor-button-text">ADD TO CALENDAR</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section
                                                data-dce-background-color="#FFFFFF"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-3cf518d elementor-section-height-min-height elementor-section-boxed elementor-section-height-default wdp-sticky-section-no"
                                                data-id="3cf518d"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-background-overlay"></div>
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div
                                                            data-dce-background-color="#A99D87"
                                                            class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6bec5e1 wdp-sticky-section-no"
                                                            data-id="6bec5e1"
                                                            data-element_type="column"
                                                            data-settings='{"background_background":"classic"}'
                                                        >
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-f86d68c animated-slow elementor-widget__width-auto wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="f86d68c"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInDown"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive;">Event</h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-506e139 animated-slow elementor-widget__width-auto wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="506e139"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive;">Wedding</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-a880b6b wdp-sticky-section-no"
                                                            data-id="a880b6b"
                                                            data-element_type="column"
                                                            data-settings='{"background_background":"classic"}'
                                                        >
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <section
                                                                        data-dce-background-overlay-color="#FFFFFF"
                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-3766b94 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                        data-id="3766b94"
                                                                        data-element_type="section"
                                                                        style="background-image: url('assets/wp-content/uploads/image2.jpg'); background-size: cover;"
                                                                    >
                                                                        <div class="elementor-background-overlay"></div>
                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                            <div class="elementor-row">
                                                                                <div
                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ef355af wdp-sticky-section-no"
                                                                                    data-id="ef355af"
                                                                                    data-element_type="column"
                                                                                >
                                                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                                                        <div class="elementor-widget-wrap">
                                                                                            <section
                                                                                                class="elementor-section elementor-inner-section elementor-element elementor-element-c7ae6ed elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                                                data-id="c7ae6ed"
                                                                                                data-element_type="section"
                                                                                                data-settings='{"_ha_eqh_enable":false}'
                                                                                            >
                                                                                                <div class="elementor-container elementor-column-gap-default">
                                                                                                    <div class="elementor-row">
                                                                                                        <div
                                                                                                            class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-c90eb1b wdp-sticky-section-no"
                                                                                                            data-id="c90eb1b"
                                                                                                            data-element_type="column"
                                                                                                        >
                                                                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                                                                <div class="elementor-widget-wrap">
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-d41c453 elementor-widget__width-auto dce_masking-none wdp-sticky-section-no elementor-widget elementor-widget-image"
                                                                                                                        data-id="d41c453"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-widget_type="image.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <div class="elementor-image">
                                                                                                                                <img decoding="async" :src="'https://admin.itsyourdayofficial.com/gallery/' + img2.file" alt="" />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-0a8eee2 elementor-widget__width-auto dce_masking-none wdp-sticky-section-no elementor-widget elementor-widget-image"
                                                                                                                        data-id="0a8eee2"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-widget_type="image.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <div class="elementor-image">
                                                                                                                                <img
                                                                                                                                    decoding="async"
                                                                                                                                    :src="'https://admin.itsyourdayofficial.com/gallery/' + img3.file"
                                                                                                                                    title=""
                                                                                                                                    alt=""
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </section>
                                                                                            <section
                                                                                                class="elementor-section elementor-inner-section elementor-element elementor-element-cf11309 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                                                data-id="cf11309"
                                                                                                data-element_type="section"
                                                                                                data-settings='{"_ha_eqh_enable":false}'
                                                                                            >
                                                                                                <div class="elementor-container elementor-column-gap-default">
                                                                                                    <div class="elementor-row">
                                                                                                        <div
                                                                                                            class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-d88eaf6 wdp-sticky-section-no"
                                                                                                            data-id="d88eaf6"
                                                                                                            data-element_type="column"
                                                                                                        >
                                                                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                                                                <div class="elementor-widget-wrap">
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-bdd5ac0 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                                        data-id="bdd5ac0"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"fadeInDown"}'
                                                                                                                        data-widget_type="heading.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <h2 class="elementor-size-default" style="font-family: 'Dancing Script', cursive; color:#79a8a9">Akad Nikah</h2>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-460e324 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                                        data-id="460e324"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                                                                        data-widget_type="heading.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <h2 class="elementor-heading-title elementor-size-default">{{moment (client.tgl_akad).locale(config.language === 1 ? 'en' : 'id').format('dddd, DD MMMM YYYY')}}</h2>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-201eadb animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                                        data-id="201eadb"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                                                                        data-widget_type="heading.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <h2 class="elementor-heading-title elementor-size-default" v-if="client.tgl_akad_selesai == null">{{ moment(client.tgl_akad).format("HH:mm") }} - Selesai</h2>
                                                                                                                            <h2 class="elementor-heading-title elementor-size-default" v-else>
                                                                                                                                {{ moment(client.tgl_akad).format("HH:mm") }} - {{ moment(client.tgl_akad_selesai).format("HH:mm") }}
                                                                                                                            </h2>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-3a9f48b animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                                        data-id="3a9f48b"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                                                                        data-widget_type="heading.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <h2 class="elementor-heading-title elementor-size-default">
                                                                                                                                <b>{{ client.nama_venue }}<br /> </b>
                                                                                                                               <p v-html="client.alamat_venue"></p>                                                                                                                            
                                                                                                                            </h2>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-8d2cbb2 elementor-mobile-align-center elementor-widget__width-auto elementor-widget-mobile__width-auto wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-button"
                                                                                                                        data-id="8d2cbb2"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                                                                        data-widget_type="button.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <div class="elementor-button-wrapper"  v-if="client.url_maps_resepsi !== null">
                                                                                                                                <a
                                                                                                                                    :href="client.url_maps" target="_blank"
                                                                                                                                    class="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink"
                                                                                                                                    role="button"
                                                                                                                                >
                                                                                                                                    <span class="elementor-button-content-wrapper">
                                                                                                                                        <span class="elementor-button-text">GOOGLE MAPS →</span>
                                                                                                                                    </span>
                                                                                                                                </a>
                                                                                                                            </div>
                                                                                                                            <div class="elementor-button-wrapper"  v-else-if="config.maps_section !== 1">
                                                                                                                                <a
                                                                                                                                    :href="client.url_maps" target="_blank"
                                                                                                                                    class="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink"
                                                                                                                                    role="button"
                                                                                                                                >
                                                                                                                                    <span class="elementor-button-content-wrapper">
                                                                                                                                        <span class="elementor-button-text">GOOGLE MAPS →</span>
                                                                                                                                    </span>
                                                                                                                                </a>
                                                                                                                            </div>
                                                                                                                            <div class="elementor-button-wrapper"  v-else-if="client.url_maps_resepsi == null && config.maps_section === 1">
                                                                                                                               <!-- kosong -->
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </section>
                                                                                            <section
                                                                                                class="elementor-section elementor-inner-section elementor-element elementor-element-6c12224 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                                                data-id="6c12224"
                                                                                                data-element_type="section"
                                                                                                data-settings='{"_ha_eqh_enable":false}'
                                                                                            >
                                                                                                <div class="elementor-container elementor-column-gap-default">
                                                                                                    <div class="elementor-row">
                                                                                                        <div
                                                                                                            class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-12e2bf2 wdp-sticky-section-no"
                                                                                                            data-id="12e2bf2"
                                                                                                            data-element_type="column"
                                                                                                        >
                                                                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                                                                <div class="elementor-widget-wrap">
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-dc6858e animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                                        data-id="dc6858e"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"fadeInDown"}'
                                                                                                                        data-widget_type="heading.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <h2 class="elementor-size-default" style="font-family: 'Dancing Script', cursive; color:#79a8a9"> {{ config.language === 1 ? 'Reception' : 'Resepsi' }}</h2>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-f5a8f27 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                                        data-id="f5a8f27"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                                                                        data-widget_type="heading.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <h2 class="elementor-heading-title elementor-size-default">{{moment (client.tgl_resepsi).locale(config.language === 1 ? 'en' : 'id').format('dddd, DD MMMM YYYY')}}</h2>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-ef8ba98 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                                        data-id="ef8ba98"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                                                                        data-widget_type="heading.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <h2 class="elementor-heading-title elementor-size-default"  v-if="client.tgl_resepsi_selesai == null">
                                                                                                                            {{ moment(client.tgl_resepsi).format("HH:mm") }} - Selesai
                                                                                                                            </h2>
                                                                                                                            <h2 class="elementor-heading-title elementor-size-default" v-else>
                                                                                                                               {{ moment(client.tgl_resepsi).format("HH:mm") }} - {{ moment(client.tgl_resepsi_selesai).format("HH:mm") }}
                                                                                                                            </h2>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-7d9272c animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                                        data-id="7d9272c"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                                                                        data-widget_type="heading.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <h2 class="elementor-heading-title elementor-size-default" >
                                                                                                                                <b>{{ client.nama_venue_resepsi }}<br /> </b>
                                                                                                                                <P v-html="client.alamat_venue_resepsi"> </P>
                                                                                                                            </h2>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-77b1bbc elementor-mobile-align-center elementor-widget__width-auto elementor-widget-mobile__width-auto wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-button"
                                                                                                                        data-id="77b1bbc"
                                                                                                                        data-element_type="widget"
                                                                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                                                                        data-widget_type="button.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <div class="elementor-button-wrapper" v-if="client.url_maps_resepsi !== null">
                                                                                                                                <a
                                                                                                                                   :href="client.url_maps_resepsi" target="_blank"
                                                                                                                                    class="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink"
                                                                                                                                    role="button"
                                                                                                                                >
                                                                                                                                    <span class="elementor-button-content-wrapper">
                                                                                                                                        <span class="elementor-button-text">GOOGLE MAPS →</span>
                                                                                                                                    </span>
                                                                                                                                </a>
                                                                                                                            </div>
                                                                                                                            <div class="elementor-button-wrapper" v-else-if="config.maps_section !== 1">
                                                                                                                                <a
                                                                                                                                    :href="client.url_maps" target="_blank"
                                                                                                                                    class="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink"
                                                                                                                                    role="button"
                                                                                                                                >
                                                                                                                                    <span class="elementor-button-content-wrapper">
                                                                                                                                        <span class="elementor-button-text">GOOGLE MAPS →</span>
                                                                                                                                    </span>
                                                                                                                                </a>
                                                                                                                            </div>
                                                                                                                            <div class="elementor-button-wrapper" v-else-if="client.url_maps_resepsi == null && config.maps_section === 1">
                                                                                                                               <!-- kosong -->
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </section>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section 
                                                v-if="config.maps_section !== 0"
                                                data-dce-background-color="#F5F2EC"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-7fa2887 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                data-id="7fa2887"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-d84e2f0 wdp-sticky-section-no" data-id="d84e2f0" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-cf26391 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="cf26391"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInDown"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive;">{{ config.language === 1 ? 'Location' : 'Lokasi Acara' }}</h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-666d668 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="666d668"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <iframe class="ftco-animate" :src="client.embed" width="95%"
                                                                                height="450"
                                                                                style="border:0; border-radius: 20px !important; margin: auto; padding-top: 30px;"
                                                                                allowfullscreen="" loading="lazy"
                                                                                referrerpolicy="no-referrer-when-downgrade">
                                                                            </iframe>
                                                                        </div>
                                                                    </div>    
                                                                    <div
                                                                        class="elementor-element elementor-element-666d668 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="666d668"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a :href="client.url_maps" target="_blank"
                                                                                    style="background-color: #79a8a9;"
                                                                                    class="elementor-button-link elementor-button elementor-size-sm"
                                                                                    role="button" >
                                                                                    <span class="elementor-button-content-wrapper">
                                                                                        <span
                                                                                            class="elementor-button-icon elementor-align-icon-left" style="color:white">
                                                                                            <i class="lni lni-map-marker"></i>
                                                                                        </span>
                                                                                        <span class="elementor-button-text" style="color:white">Google Maps</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            
                                            <!-- RSVP BY WA -->
                                            <!-- <section
                                                data-dce-background-color="#F5F2EC"
                                                data-dce-background-overlay-color="#F5F2EC"
                                                data-dce-background-image-url="assets/wp-content/uploads/2022/08/sample-luxury-2.png"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-ea4c586 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                data-id="ea4c586"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-background-overlay"></div>
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-eff1217 wdp-sticky-section-no" data-id="eff1217" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap" style="padding-bottom: 0px;">
                                                                    <div
                                                                        class="elementor-element elementor-element-c2bef76 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="c2bef76"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInDown"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default">
                                                                                RSVP by WA
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-ee6f05c animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-weddingpress-commentkit"
                                                                        data-id="ee6f05c"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeIn"}'
                                                                        data-widget_type="weddingpress-commentkit.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <form>
                                                                                <p class="comment-form-author wdp-field-1" style="margin-bottom:0px;">
                                                                                    <input 
                                                                                        type="text" 
                                                                                        aria-required="true" 
                                                                                        class="wdp-input" 
                                                                                        :placeholder="config.language === 1 ? 'Your Name' : 'Nama Anda'"  
                                                                                        v-model="name" required nofocus 
                                                                                        style="
                                                                                        border-bottom: 1px solid #a99d87 !important; 
                                                                                        background: #fbfbfb !important;
                                                                                        border: 1px solid #d5deea;
                                                                                        "/>
                                                                                </p>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                             -->
                                            <section
                                             v-if="config.gallery_section !== 0"
                                                data-dce-background-color="#F5F2EC"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-7fa2887 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                data-id="7fa2887"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-d84e2f0 wdp-sticky-section-no" data-id="d84e2f0" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-cf26391 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="cf26391"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInDown"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive;">{{ config.language === 1 ? 'Our Gallery' : 'Galeri' }}</h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-a26b801 wdp-sticky-section-no elementor-widget elementor-widget-image-carousel"
                                                                        data-id="a26b801"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="image-carousel.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-image-carousel-wrapper swiper-container" dir="ltr">
                                                                                <vue-horizontal :button="false" ref="horizontal"
                                                                                        @scroll-debounce="onScroll">
                                                                                        <div class="swiper-slide" v-for="(item, index) in gallery" :key="index" style="padding-right: 5px;">
                                                                                            <figure class="swiper-slide-inner">
                                                                                                <img v-img:group
                                                                                                    decoding="async"
                                                                                                    class="swiper-slide-image"
                                                                                                    :src="'https://admin.itsyourdayofficial.com/gallery/' + item.file"
                                                                                                    alt=""
                                                                                                />
                                                                                            </figure>
                                                                                        </div>       
                                                                                </vue-horizontal>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <!-- KISAH CINTA -->
                                            <section 
                                                v-if="config.story_section != 0"
                                                data-dce-background-color="#FDFCF9"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-56c6e6c elementor-section-height-min-height elementor-section-boxed elementor-section-height-default wdp-sticky-section-no"
                                                data-id="56c6e6c"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-910c519 wdp-sticky-section-no" data-id="910c519" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-cf26391 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="cf26391"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInDown"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive;">{{ config.language === 1 ? 'Love Story' : 'Kisah Cinta' }}</h2>
                                                                        </div>
                                                                    </div>
                                                                    <section
                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-960964f elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                        data-id="960964f"
                                                                        data-element_type="section"
                                                                        data-settings='{"_ha_eqh_enable":false}'
                                                                    >
                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                            <div class="elementor-row">
                                                                                <div
                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-e80a402 wdp-sticky-section-no"
                                                                                    data-id="e80a402"
                                                                                    data-element_type="column"
                                                                                >
                                                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                                                        <div class="elementor-widget-wrap">
                                                                                            <div
                                                                                                class="elementor-element elementor-element-cc5a138 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="cc5a138"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type1 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <img decoding="async" width="2560" height="1707"
                                                                                                        v-if="story.type1.type == 'foto'"
                                                                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media1.media"
                                                                                                        class="attachment-full size-full wp-image-17686" alt=""
                                                                                                        loading="lazy" sizes="(max-width: 2560px) 100vw, 2560px" />
                                                                                                    <video v-if="story.type1.type == 'video'" width="100%" height="280px;"
                                                                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media1.media + '#t=0.001'"
                                                                                                        type="video/mp4" controls preload="metadata"></video>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-cc5a138 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="cc5a138"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default" v-if="story.type1 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default">{{story.title1.title}}</h2>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-8521f15 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="8521f15"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type1 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default"><p v-html="story.description1.description"></p></h2>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-08e70a8 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="08e70a8"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type2 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <img decoding="async" width="2560" height="1707"
                                                                                                        v-if="story.type2.type == 'foto'"
                                                                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media2.media"
                                                                                                        class="attachment-full size-full wp-image-17683" alt=""
                                                                                                        loading="lazy" sizes="(max-width: 2560px) 100vw, 2560px" />
                                                                                                    <video v-if="story.type2.type == 'video'" width="100%" height="280px;"
                                                                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media2.media + '#t=0.001'"
                                                                                                        type="video/mp4" controls preload="metadata"></video>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-08e70a8 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="08e70a8"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type2 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default">{{story.title2.title}}</h2>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-2789515 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="2789515"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type2 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default">
                                                                                                        <p v-html="story.description2.description"></p>
                                                                                                    </h2>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-7409dcb animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="7409dcb"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type3 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <img decoding="async" width="2560" height="1707"
                                                                                                        v-if="story.type3.type == 'foto'"
                                                                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media3.media"
                                                                                                        class="attachment-full size-full wp-image-17685" alt=""
                                                                                                        loading="lazy" sizes="(max-width: 2560px) 100vw, 2560px" />
                                                                                                    <video v-if="story.type3.type == 'video'" width="100%" height="280px;"
                                                                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media3.media + '#t=0.001'"
                                                                                                        type="video/mp4" controls preload="metadata"></video>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-7409dcb animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="7409dcb"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type3 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default">{{story.title3.title}}</h2>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-3c912cd animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="3c912cd"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type3 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default">
                                                                                                        <p v-html="story.description3.description"></p>
                                                                                                    </h2>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-e670c31 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="e670c31"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type4 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <img decoding="async" width="2560" height="1707"
                                                                                                        v-if="story.type4.type == 'foto'"
                                                                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media4.media"
                                                                                                        class="attachment-full size-full wp-image-17685" alt=""
                                                                                                        loading="lazy" sizes="(max-width: 2560px) 100vw, 2560px" />
                                                                                                    <video v-if="story.type4.type == 'video'" width="100%" height="280px;"
                                                                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media4.media + '#t=0.001'"
                                                                                                        type="video/mp4" controls preload="metadata"></video>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-e670c31 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="e670c31"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type4 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default">{{story.title4.title}}</h2>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-17187db animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="17187db"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type4 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default">
                                                                                                        <p v-html="story.description4.description"></p>
                                                                                                    </h2>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-7409dcb animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="7409dcb"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type5 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <img decoding="async" width="2560" height="1707"
                                                                                                        v-if="story.type5.type == 'foto'"
                                                                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media5.media"
                                                                                                        class="attachment-full size-full wp-image-17685" alt=""
                                                                                                        loading="lazy" sizes="(max-width: 2560px) 100vw, 2560px" />
                                                                                                    <video v-if="story.type5.type == 'video'" width="100%" height="280px;"
                                                                                                        :src="'https://admin.itsyourdayofficial.com/story/' + story.media5.media + '#t=0.001'"
                                                                                                        type="video/mp4" controls preload="metadata"></video>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-7409dcb animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="7409dcb"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type5 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default">{{story.title5.title}}</h2>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                class="elementor-element elementor-element-3c912cd animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                                                data-id="3c912cd"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"fadeInUp"}'
                                                                                                data-widget_type="heading.default"
                                                                                                v-if="story.type5 !== undefined"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <h2 class="elementor-heading-title elementor-size-default">
                                                                                                        <p v-html="story.description5.description"></p>
                                                                                                    </h2>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            

                                            <!-- UCAPAN PERNIKAHAN -->
                                            <section
                                                data-dce-background-color="#F5F2EC"
                                                data-dce-background-overlay-color="#F5F2EC"
                                                data-dce-background-image-url="assets/wp-content/uploads/2022/08/sample-luxury-2.png"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-ea4c586 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                data-id="ea4c586"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-background-overlay"></div>
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-eff1217 wdp-sticky-section-no" data-id="eff1217" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap" style="padding-bottom: 0px;">
                                                                    <div
                                                                        class="elementor-element elementor-element-c2bef76 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="c2bef76"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInDown"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive;">
                                                                                {{ config.language === 1 ? "Wedding Wish" : 'Ucapan Pernikahan' }}
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-ee6f05c animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-weddingpress-commentkit"
                                                                        data-id="ee6f05c"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeIn"}'
                                                                        data-widget_type="weddingpress-commentkit.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <div class="wdp-wrapper wdp-custom wdp-border" style="overflow: hidden;">
                                                                                
                                                                                <div id="wdp-wrap-commnent-172600" class="wdp-wrap-comments" >
                                                                                    <div id="wdp-wrap-form-172600" class="wdp-wrap-form wdp-clearfix">
                                                                                        <div id="wdp-container-form-172600" class="wdp-container-form wdp-no-login">
                                                                                            <div id="respond-172600" class="respond wdp-clearfix">
                                                                                                <form action="" method="post" id="commentform-172600">
                                                                                                    <div>
                                                                                                        <p class="comment-form-author wdp-field-1" style="margin-bottom:0px;">
                                                                                                            <input id="author" name="author" type="text" aria-required="true" class="wdp-input" :placeholder="config.language === 1 ? 'Your Name' : 'Nama Anda'"  v-model="submit.name" required nofocus />
                                                                                                        </p>
                                                                                                        <span v-if="errors.name"
                                                                                                            style="color:white; margin-bottom:10px; background:rgb(169, 157, 135); border-radius: 50px; padding-left: 10px; padding-right: 10px;"
                                                                                                            class="badge badge-danger rounded-pill">
                                                                                                            {{ config.language === 1 ? "Please input your name" : 'Silahkan tulis nama anda' }}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <div class="wdp-wrap-select" style="margin-bottom:5px;">
                                                                                                        <select class="waci_comment wdp-select" name="konfirmasi" v-model="submit.confirmation">
                                                                                                            <option value="" disabled selected>{{ config.language === 1 ? "Select Confirm Attendance" : 'Pilih Konfirmasi Kehadiran' }}</option>
                                                                                                            <option value="hadir">{{ config.language === 1 ? "Attending" : 'Hadir' }}</option>
                                                                                                            <option value="tidak">{{ config.language === 1 ? "Not Attending" : 'Tidak Hadir' }}</option>
                                                                                                            <option value="mungkin">{{ config.language === 1 ? "Maybe" : 'Mungkin Hadir' }}</option>
                                                                                                        </select>
                                                                                                        
                                                                                                    </div>
                                                                                                    <span v-if="errors.confirmation"
                                                                                                            style="color:white; margin-bottom:10px; background:rgb(169, 157, 135); border-radius: 50px; padding-left: 10px; padding-right: 10px;"
                                                                                                            class="badge badge-danger rounded-pill">
                                                                                                            {{ config.language === 1 ? "Please confirm your attendance" : 'Harap pilih konfirmasi kehadiran anda' }}
                                                                                                    </span>
                                                                                                    <div class="wdp-wrap-textarea" style="padding-top:5px;">
                                                                                                        <textarea
                                                                                                            v-model="submit.comment"
                                                                                                            id="wdp-textarea-172600"
                                                                                                            class="waci_comment wdp-textarea autosize-textarea"
                                                                                                            name="comment"
                                                                                                            aria-required="true"
                                                                                                            :placeholder="config.language === 1 ? 'Give greetings and prayers to the bride and groom' : 'Berikan Ucapan & Doa untuk Kedua Mempelai'" 
                                                                                                            rows="3"
                                                                                                        ></textarea>
                                                                                                    </div>
                                                                                                    
                                                                                                    
                                                                                                    <div class="wdp-wrap-radio" style="display: none;">
                                                                                                        <label for="hadir" class="txt_jmlh_tamu" style="box-shadow: none;">
                                                                                                            <b>Jumlah Tamu Hadir :</b>
                                                                                                        </label>
                                                                                                        <div style="margin-top: 5px; display: flex; justify-content: space-evenly;">
                                                                                                            <div class="optionnya">
                                                                                                                <input name="tamu" id="tamu0" type="radio" value="1" style="margin-right: 4px; height: 10px;" />
                                                                                                                <label for="tamu0" style="margin-bottom: 2px !important;"> 1 <span class="txt_tamu">Tamu</span> </label>
                                                                                                            </div>
                                                                                                            <div class="optionnya">
                                                                                                                <input name="tamu" id="tamu1" type="radio" value="2" style="margin-right: 4px; height: 10px;" />
                                                                                                                <label for="tamu1" style="margin-bottom: 2px !important;"> 2 <span class="txt_tamu">Tamu</span> </label>
                                                                                                            </div>
                                                                                                            <div class="optionnya">
                                                                                                                <input name="tamu" id="tamu2" type="radio" value="3" style="margin-right: 4px; height: 10px;" />
                                                                                                                <label for="tamu2" style="margin-bottom: 2px !important;"> 3 <span class="txt_tamu">Tamu</span> </label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="elementor-button-wrapper" style="text-align: right;">
                                                                                                        <button  
                                                                                                            @click.prevent="post"
                                                                                                            class="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink"
                                                                                                            role="button"
                                                                                                            style="background-color:#79a8a9; border:#79a8a9;"
                                                                                                        >
                                                                                                            <span class="elementor-button-content-wrapper">
                                                                                                                <span class="elementor-button-text">Submit →</span>
                                                                                                            </span>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </form>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div id=" wdp-comment-status-172600 " class="wdp-comment-status"></div>
                                                                                    <ul
                                                                                        id="wdp-container-comment-172600"
                                                                                        class="wdp-container-comments wdp-order-DESC wdp-has-22-comments wdp-multiple-comments"
                                                                                        data-order="DESC"
                                                                                        style="display: block;"
                                                                                    >
                                                                                        <li class="comment even thread-even depth-1 wdp-item-comment" id="wdp-item-comment-90712" data-likes="0" v-for="h in hopes" :key="h.id">
                                                                                            <div id="wdp-comment-90712" class="wdp-comment wdp-clearfix">
                                                                                                <div class="wdp-comment-avatar">
                                                                                                    <img
                                                                                                        alt=""
                                                                                                        src="assets/images/logo.png"
                                                                                                        srcset="assets/images/logo.png 2x"
                                                                                                        height="96"
                                                                                                        width="96"
                                                                                                    />
                                                                                                </div>
                                                                                                <!--.wdp-comment-avatar-->

                                                                                                <div class="wdp-comment-content">
                                                                                                    <div class="wdp-comment-info">
                                                                                                        <a class="wdp-commenter-name" title="Djulian Dini &amp; Partner">{{h.name}} </a>

                                                                                                        <span v-if="h.confirmation == 'hadir'" class=""> <i class="fas fa-smile"></i> {{ config.language === 1 ? " Attend" : ' Hadir' }}</span>
                                                                                                        <span v-if="h.confirmation == 'mungkin'" class=""> <i class="fas fa-meh"></i> {{ config.language === 1 ? " Maybe Attend" : ' Mungkin Hadir' }}</span>
                                                                                                        <span v-if="h.confirmation == 'tidak'" class=""> <i class="fas fa-frown"></i> {{ config.language === 1 ? " Not Attend" : ' Tidak Hadir' }}</span>
                                                                                                    </div>
                                                                                                    <!--.wdp-comment-info-->

                                                                                                    <div class="wdp-comment-text">
                                                                                                        <p v-html="h.comment"></p>
                                                                                                    </div>
                                                                                                    <!--.wdp-comment-text-->

                                                                                                    <div class="">
                                                                                                        <!-- Jam -->
                                                                                                        <span class="">
                                                                                                            <i class="fas fa-clock"></i>

                                                                                                            <i> {{ getElapsedTime(h.created_at) }}</i>
                                                                                                        </span>
                                                                                                    </div>

                                                                                                    <div class="wdp-comment-actions" style="display: none;"></div>
                                                                                                    <!--.wdp-comment-actions-->
                                                                                                </div>
                                                                                                <!--.wdp-comment-content-->
                                                                                            </div>
                                                                                            <!--.wdp-comment-->

                                                                                            <!--</li>-->
                                                                                        </li>
                                                                                        <!-- #comment-## -->
                                                                                    </ul>

                                                                                    <div class="wdp-holder-172600 wdp-holder"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div
                                                                        class="elementor-element elementor-element-fc13e0d wdp-sticky-section-no elementor-widget elementor-widget-html"
                                                                        data-id="fc13e0d"
                                                                        data-element_type="widget"
                                                                        data-widget_type="html.default"
                                                                    >
                                                                        <div class="elementor-widget-container"></div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            
                                            <!-- AMPLOP DIGITAL -->
                                            <section
                                            v-if="config.gift_section !== 0"
                                                data-dce-background-color="#A99D87"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-69c9018 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                data-id="69c9018"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-de87ad8 wdp-sticky-section-no" data-id="de87ad8" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-aeb2e16 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="aeb2e16"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInDown"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" style="font-family: 'Dancing Script', cursive;">{{ config.language === 1 ? "Wedding Gift" : 'Hadiah Pernikahan' }}</h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-141ff40 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="141ff40"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default">
                                                                                {{ config.language === 1 ? "For those who want to send wedding gifts, You can transfer to this account number below:" : 'Bagi yang ingin mengirimkan kado pernikahan, bisa transfer ke nomor rekening di bawah ini:' }}
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-1db3065 animated-slow elementor-align-center wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-button"
                                                                        data-id="1db3065"
                                                                        data-element_type="widget"
                                                                        id="btn_gift"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="button.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a class="elementor-button elementor-size-sm elementor-animation-shrink" role="button">
                                                                                    <span class="elementor-button-content-wrapper">
                                                                                        <span class="elementor-button-text">{{ config.language === 1 ? "CLICK HERE" : 'KLIK DISINI' }} →</span>
                                                                                    </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div
                                                                        class="elementor-element elementor-element-5e385a3 elementor-widget__width-auto animated-slow dce_masking-none wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-image"
                                                                        data-id="5e385a3"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="image.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-image">
                                                                                <img
                                                                                    decoding="async"
                                                                                    src="assets/wp-content/uploads/elementor/thumbs/img_tria-danu-17-q4sqyvsb4uuugjbv5b4ofrc09psr1myslkfq77gqvk.jpg"
                                                                                    title="img_tria-danu (17)"
                                                                                    alt="img_tria-danu (17)"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div> -->
                                                                    <section
                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-4b39e42 elementor-section-height-min-height wdp-sticky-section-yes elementor-section-boxed elementor-section-height-default wdp-sticky-section-positon-bottom"
                                                                        data-id="4b39e42"
                                                                        data-element_type="section"
                                                                        id="sec_gift"
                                                                        data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                                                    >
                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                            <div class="elementor-row">
                                                                                <div
                                                                                    data-dce-background-color="#F5F2EC"
                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-0fd6034 wdp-sticky-section-no"
                                                                                    data-id="0fd6034"
                                                                                    data-element_type="column"
                                                                                    id="sec_gift_col"
                                                                                    data-settings='{"background_background":"classic"}'
                                                                                >
                                                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                                                        <div class="elementor-widget-wrap">
                                                                                            <section
                                                                                                data-dce-background-color="#A99D87"
                                                                                                class="elementor-section elementor-inner-section elementor-element elementor-element-055ee47 animated-slow elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
                                                                                                data-id="055ee47"
                                                                                                data-element_type="section"
                                                                                                data-settings='{"background_background":"classic","animation":"fadeIn","_ha_eqh_enable":false}'
                                                                                            >
                                                                                                <div class="elementor-container elementor-column-gap-default">
                                                                                                    <div class="elementor-row">
                                                                                                        <div
                                                                                                            class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-0df0ff6 wdp-sticky-section-no"
                                                                                                            data-id="0df0ff6"
                                                                                                            data-element_type="column"
                                                                                                        >
                                                                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                                                                <div class="elementor-widget-wrap">
                                                                                                                    <div
                                                                                                                        class="elementor-element elementor-element-0115614 elementor-view-default wdp-sticky-section-no elementor-widget elementor-widget-icon"
                                                                                                                        data-id="0115614"
                                                                                                                        data-element_type="widget"
                                                                                                                        id="btn_close_gift"
                                                                                                                        data-widget_type="icon.default"
                                                                                                                    >
                                                                                                                        <div class="elementor-widget-container">
                                                                                                                            <div class="elementor-icon-wrapper">
                                                                                                                                <div class="elementor-icon">
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        id="a80fd312-fd48-4b1e-829e-730106472285"
                                                                                                                                        data-name="Layer 1"
                                                                                                                                        viewBox="0 0 100 100"
                                                                                                                                    >
                                                                                                                                        <title>close</title>
                                                                                                                                        <path
                                                                                                                                            d="M62.05,50,80.69,31.36a3.6,3.6,0,0,0,0-5.1l-7-7a3.6,3.6,0,0,0-5.1,0L50,38,31.36,19.31a3.6,3.6,0,0,0-5.1,0l-7,7a3.6,3.6,0,0,0,0,5.1L38,50,19.31,68.64a3.6,3.6,0,0,0,0,5.1l7,7a3.6,3.6,0,0,0,5.1,0L50,62.05,68.64,80.69a3.6,3.6,0,0,0,5.1,0l7-7a3.6,3.6,0,0,0,0-5.1Z"
                                                                                                                                        ></path>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </section>
                                                                                            <section
                                                                                                class="elementor-section elementor-inner-section elementor-element elementor-element-58845fe elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                                                data-id="58845fe"
                                                                                                data-element_type="section"
                                                                                                data-settings='{"_ha_eqh_enable":false}'
                                                                                            >
                                                                                                <div class="elementor-container elementor-column-gap-default">
                                                                                                    <div class="elementor-row">
                                                                                                        <div
                                                                                                            class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-d51a7d9 wdp-sticky-section-no"
                                                                                                            data-id="d51a7d9"
                                                                                                            data-element_type="column"
                                                                                                        >
                                                                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                                                                <div class="elementor-widget-wrap">
                                                                                                                    <section
                                                                                                                        data-dce-background-color="#FFFFFF"
                                                                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-f5eef84 animated-slow elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                                                                        data-id="f5eef84"
                                                                                                                        data-element_type="section"
                                                                                                                        data-settings='{"background_background":"gradient","animation":"none","animation_delay":800,"_ha_eqh_enable":false}'
                                                                                                                    >
                                                                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                                                                            <div class="elementor-row">
                                                                                                                                <div
                                                                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-1cbd589 wdp-sticky-section-no"
                                                                                                                                    data-id="1cbd589"
                                                                                                                                    data-element_type="column"
                                                                                                                                >
                                                                                                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                                                                                                        <div class="elementor-widget-wrap">
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-8f256dc wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="8f256dc"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank == 'BCA'"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"></h2>
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank == 'BRI'"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"></h2>
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank == 'OVO'"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> </h2>
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank == 'MANDIRI'"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"></h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-51bbe18 wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="51bbe18"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default">{{ client.no_acc }}</h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-2469f5f wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="2469f5f"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default">a/n {{client.acc_bank}}</h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-bb903f9 elementor-align-center elementor-widget__width-auto wdp-sticky-section-no elementor-widget elementor-widget-dce-copy-to-clipboard"
                                                                                                                                                data-id="bb903f9"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="dce-copy-to-clipboard.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <div class="dce-clipboard-wrapper dce-clipboard-wrapper-text">
                                                                                                                                                        <div>
                                                                                                                                                            <button data-target="#collapseExample"  class="elementor-button elementor-size-sm" type="button" @click.prevent="myFunction">
                                                                                                                                                                <span class="elementor-button-content-wrapper dce-flexbox">
                                                                                                                                                                    <span class="elementor-button-icon elementor-align-icon-left">
                                                                                                                                                                        <i class="lni lni-clipboard"></i>
                                                                                                                                                                    </span>
                                                                                                                                                                    <span class="elementor-button-text">{{ buttonText }}</span>
                                                                                                                                                                </span>
                                                                                                                                                            </button>
                                                                                                                                                        </div>
                                                                                                                                                        <input
                                                                                                                                                            class="elementor-size-sm dce-clipboard-value elementor-field-textual dce-offscreen dce-form-control"
                                                                                                                                                            id="myInput"
                                                                                                                                                            aria-hidden="true"
                                                                                                                                                            type="text"
                                                                                                                                                            v-model="client.no_acc"
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </section>
                                                                                                                    <section v-if="client.bank2 != null"
                                                                                                                        data-dce-background-color="#FFFFFF"
                                                                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-ffd61c1 animated-slow elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                                                                        data-id="ffd61c1"
                                                                                                                        data-element_type="section"
                                                                                                                        data-settings='{"background_background":"gradient","animation":"none","animation_delay":800,"_ha_eqh_enable":false}'
                                                                                                                    >
                                                                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                                                                            <div class="elementor-row">
                                                                                                                                <div
                                                                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-34191e3 wdp-sticky-section-no"
                                                                                                                                    data-id="34191e3"
                                                                                                                                    data-element_type="column"
                                                                                                                                >
                                                                                                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                                                                                                        <div class="elementor-widget-wrap">
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-2eab723 wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="2eab723"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank2 == 'BCA'"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"></h2>
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank2 == 'BRI'"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"></h2>
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank2 == 'OVO'"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> </h2>
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank2 == 'MANDIRI'"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"></h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-9f14eb0 wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="9f14eb0"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default">{{ client.no_acc2 }}</h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-acf5103 wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="acf5103"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default">a/n {{client.acc_bank2}}</h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-d56e21c elementor-align-center elementor-widget__width-auto wdp-sticky-section-no elementor-widget elementor-widget-dce-copy-to-clipboard"
                                                                                                                                                data-id="d56e21c"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="dce-copy-to-clipboard.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <div class="dce-clipboard-wrapper dce-clipboard-wrapper-text">
                                                                                                                                                        <div>
                                                                                                                                                            <button data-target="#collapseExample2"  class="elementor-button elementor-size-sm" type="button" @click.prevent="myFunction2">
                                                                                                                                                                <span class="elementor-button-content-wrapper dce-flexbox">
                                                                                                                                                                    <span class="elementor-button-icon elementor-align-icon-left">
                                                                                                                                                                        <i class="lni lni-clipboard"></i>
                                                                                                                                                                    </span>
                                                                                                                                                                    <span class="elementor-button-text">{{ buttonText2 }}</span>
                                                                                                                                                                </span>
                                                                                                                                                            </button>
                                                                                                                                                        </div>
                                                                                                                                                        <input
                                                                                                                                                            class="elementor-size-sm dce-clipboard-value elementor-field-textual dce-offscreen dce-form-control"
                                                                                                                                                            id="myInput2"
                                                                                                                                                            aria-hidden="true"
                                                                                                                                                            type="text"
                                                                                                                                                            v-model="client.no_acc2"
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </section>
                                                                                                                    <section v-if="client.bank3 != null"
                                                                                                                        data-dce-background-color="#FFFFFF"
                                                                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-ffd61c1 animated-slow elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                                                                        data-id="ffd61c1"
                                                                                                                        data-element_type="section"
                                                                                                                        data-settings='{"background_background":"gradient","animation":"none","animation_delay":800,"_ha_eqh_enable":false}'
                                                                                                                    >
                                                                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                                                                            <div class="elementor-row">
                                                                                                                                <div
                                                                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-34191e3 wdp-sticky-section-no"
                                                                                                                                    data-id="34191e3"
                                                                                                                                    data-element_type="column"
                                                                                                                                >
                                                                                                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                                                                                                        <div class="elementor-widget-wrap">
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-2eab723 wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="2eab723"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank3 == 'BCA'"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"></h2>
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank3 == 'BRI'"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"></h2>
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank3 == 'OVO'"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> </h2>
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank3 == 'MANDIRI'"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"></h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-9f14eb0 wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="9f14eb0"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default">{{ client.no_acc3 }}</h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-acf5103 wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="acf5103"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default">a/n {{client.acc_bank3 }}</h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-d56e21c elementor-align-center elementor-widget__width-auto wdp-sticky-section-no elementor-widget elementor-widget-dce-copy-to-clipboard"
                                                                                                                                                data-id="d56e21c"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="dce-copy-to-clipboard.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <div class="dce-clipboard-wrapper dce-clipboard-wrapper-text">
                                                                                                                                                        <div>
                                                                                                                                                            <button data-target="#collapseExample2"  class="elementor-button elementor-size-sm" type="button" @click.prevent="myFunction3">
                                                                                                                                                                <span class="elementor-button-content-wrapper dce-flexbox">
                                                                                                                                                                    <span class="elementor-button-icon elementor-align-icon-left">
                                                                                                                                                                        <i class="lni lni-clipboard"></i>
                                                                                                                                                                    </span>
                                                                                                                                                                    <span class="elementor-button-text">{{ buttonText3 }}</span>
                                                                                                                                                                </span>
                                                                                                                                                            </button>
                                                                                                                                                        </div>
                                                                                                                                                        <input
                                                                                                                                                            class="elementor-size-sm dce-clipboard-value elementor-field-textual dce-offscreen dce-form-control"
                                                                                                                                                            id="myInput3"
                                                                                                                                                            aria-hidden="true"
                                                                                                                                                            type="text"
                                                                                                                                                            v-model="client.no_acc3"
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </section>
                                                                                                                    <section v-if="client.bank4 != null"
                                                                                                                        data-dce-background-color="#FFFFFF"
                                                                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-ffd61c1 animated-slow elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                                                                        data-id="ffd61c1"
                                                                                                                        data-element_type="section"
                                                                                                                        data-settings='{"background_background":"gradient","animation":"none","animation_delay":800,"_ha_eqh_enable":false}'
                                                                                                                    >
                                                                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                                                                            <div class="elementor-row">
                                                                                                                                <div
                                                                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-34191e3 wdp-sticky-section-no"
                                                                                                                                    data-id="34191e3"
                                                                                                                                    data-element_type="column"
                                                                                                                                >
                                                                                                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                                                                                                        <div class="elementor-widget-wrap">
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-2eab723 wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="2eab723"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank4 == 'BCA'"><img src="assets/images/bca.png" alt="" style="width: 35%;padding: 0;"></h2>
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank4 == 'BRI'"><img src="assets/images/bri.png" alt="" style="width: 35%;padding: 0;"></h2>
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank4 == 'OVO'"><img src="assets/images/ovo.png" alt="" style="width: 35%;padding: 0;"> </h2>
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default" v-if="client.bank4 == 'MANDIRI'"><img src="assets/images/mandiri.png" alt="" style="width: 35%;padding: 0;"></h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-9f14eb0 wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="9f14eb0"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default">{{ client.no_acc4 }}</h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-acf5103 wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="acf5103"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default">a/n {{client.acc_bank4}}</h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-d56e21c elementor-align-center elementor-widget__width-auto wdp-sticky-section-no elementor-widget elementor-widget-dce-copy-to-clipboard"
                                                                                                                                                data-id="d56e21c"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="dce-copy-to-clipboard.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <div class="dce-clipboard-wrapper dce-clipboard-wrapper-text">
                                                                                                                                                        <div>
                                                                                                                                                            <button data-target="#collapseExample2"  class="elementor-button elementor-size-sm" type="button" @click.prevent="myFunction4">
                                                                                                                                                                <span class="elementor-button-content-wrapper dce-flexbox">
                                                                                                                                                                    <span class="elementor-button-icon elementor-align-icon-left">
                                                                                                                                                                        <i class="lni lni-clipboard"></i>
                                                                                                                                                                    </span>
                                                                                                                                                                    <span class="elementor-button-text">{{ buttonText4 }}</span>
                                                                                                                                                                </span>
                                                                                                                                                            </button>
                                                                                                                                                        </div>
                                                                                                                                                        <input
                                                                                                                                                            class="elementor-size-sm dce-clipboard-value elementor-field-textual dce-offscreen dce-form-control"
                                                                                                                                                            id="myInput4"
                                                                                                                                                            aria-hidden="true"
                                                                                                                                                            type="text"
                                                                                                                                                            v-model="client.no_acc4"
                                                                                                                                                        />
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </section>
                                                                                                                    <section
                                                                                                                        data-dce-background-color="#FFFFFF"
                                                                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-70a865f animated-slow elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                                                                                                        data-id="70a865f"
                                                                                                                        data-element_type="section"
                                                                                                                        data-settings='{"background_background":"gradient","animation":"none","animation_delay":800,"_ha_eqh_enable":false}'
                                                                                                                        v-if="client.gift_address !== null"
                                                                                                                    >
                                                                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                                                                            <div class="elementor-row">
                                                                                                                                <div
                                                                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-d5dec7e wdp-sticky-section-no"
                                                                                                                                    data-id="d5dec7e"
                                                                                                                                    data-element_type="column"
                                                                                                                                >
                                                                                                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                                                                                                        <div class="elementor-widget-wrap">
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-c7d649b wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="c7d649b"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default">GIFT</h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-45d4fca wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="45d4fca"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default">
                                                                                                                                                        Penerima:<br />
                                                                                                                                                        {{ client.nama_wanita }} dan {{ client.nama_pria }}
                                                                                                                                                    </h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-d59522a wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                                                                data-id="d59522a"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="heading.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <h2 class="elementor-heading-title elementor-size-default">
                                                                                                                                                        <p v-html="client.gift_address"></p>
                                                                                                                                                    </h2>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div
                                                                                                                                                class="elementor-element elementor-element-a161da6 elementor-align-center elementor-widget__width-auto wdp-sticky-section-no elementor-widget elementor-widget-dce-copy-to-clipboard"
                                                                                                                                                data-id="a161da6"
                                                                                                                                                data-element_type="widget"
                                                                                                                                                data-widget_type="dce-copy-to-clipboard.default"
                                                                                                                                            >
                                                                                                                                                <div class="elementor-widget-container">
                                                                                                                                                    <div class="dce-clipboard-wrapper dce-clipboard-wrapper-textarea">
                                                                                                                                                        <button data-target="#collapseExample2"  class="elementor-button elementor-size-sm" type="button" @click.prevent="myFunction5">
                                                                                                                                                            <span class="elementor-button-content-wrapper dce-flexbox">
                                                                                                                                                                <span class="elementor-button-icon elementor-align-icon-left">
                                                                                                                                                                    <i class="lni lni-clipboard"></i>
                                                                                                                                                                </span>
                                                                                                                                                                <span class="elementor-button-text">{{ buttonText5 }}</span>
                                                                                                                                                            </span>
                                                                                                                                                        </button>
                                                                                                                                                        <textarea
                                                                                                                                                            class="elementor-size-sm dce-clipboard-value elementor-field-textual dce-offscreen dce-block"
                                                                                                                                                            id="myInput5"
                                                                                                                                                            aria-hidden="true"
                                                                                                                                                            v-model="plainAddress"
                                                                                                                                                        >
                                                                                                                                                        </textarea>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </section>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </section>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <!-- TURUT MENGUNDANG -->

                                            <!-- <section
                                                data-dce-background-color="#f4f7f7"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-184648d elementor-section-height-min-height elementor-section-boxed elementor-section-height-default wdp-sticky-section-no"
                                                data-id="184648d"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-container elementor-column-gap-default" >
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-de87ad8 wdp-sticky-section-no" data-id="de87ad8" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-aeb2e16 animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="aeb2e16"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInDown"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default">{{ config.language === 1 ? "Also Invite" : 'Turut Mengundang' }}</h2>
                                                                        </div>                                                                        
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-97fd3cb animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="97fd3cb"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container" style="text-align: justify">
                                                                                
                                                                                <span v-for="invite in alsoInvite" :key="invite.id" style="color: #79a8a9;">
                                                                                    <p v-if="invite.type === 'wanita'"><i class="lni lni-checkmark-circle"></i> {{ invite.invite }}</p>
                                                                                </span>
                                                                            
                                                                                <span v-for="invite in alsoInvite" :key="invite.id" style="color: #79a8a9;">
                                                                                    <p v-if="invite.type === 'pria'"><i class="lni lni-checkmark-circle"></i> {{ invite.invite }}</p>
                                                                                </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section> -->


                                            <section
                                                data-dce-background-overlay-color="#A99D8700"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-8ab1d9a elementor-section-height-min-height elementor-section-boxed elementor-section-height-default wdp-sticky-section-no"
                                                data-id="8ab1d9a"
                                                data-element_type="section"
                                                v-bind:style="{ 
                                                    'background-image': 'url(https://admin.itsyourdayofficial.com/gallery/'+img4.file+')',
                                                    'background-size': 'cover',
                                                    'background-position': 'center',
                                                    'display': 'flex',
                                                    'justify-content': 'center',
                                                    'align-items': 'center',
                                                }"
                                                data-settings='{
                                                    "background_background":"slideshow",
                                                    "background_slideshow_slide_duration":1000,
                                                    "background_slideshow_transition_duration":5000,
                                                    "background_slideshow_slide_transition":"slide_up",
                                                    "background_slideshow_loop":"yes",
                                                    "_ha_eqh_enable":false
                                                }'
                                            >
                                                <div class="elementor-background-overlay"></div>
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6a60023 wdp-sticky-section-no" data-id="6a60023" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-b73e346 animated-slow wdp-sticky-section-no elementor-widget elementor-widget-heading animated fadeInDown"
                                                                        data-id="b73e346"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInDown"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container"><h2 class="elementor-heading-title elementor-size-default">{{ config.language === 1 ? "Thank You" : 'Terimakasih' }}</h2></div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-7b50b4d animated-slow wdp-sticky-section-no elementor-widget elementor-widget-heading animated fadeInUp"
                                                                        data-id="7b50b4d"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default">
                                                                                {{ config.language === 1 ? "It is a joy and honor for us, if you are willing to attend and give us your blessing." : 'Merupakan suatu kebahagiaan dan kehormatan bagi kami, apabila Bapak/Ibu/Saudara/i, berkenan hadir dan memberikan do’a restu kepada kami.' }}
                                                                            </h2>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-066c6bf wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                        data-id="066c6bf"
                                                                        data-element_type="widget"
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container"><h2 class="elementor-heading-title elementor-size-default">{{ config.language === 1 ? "WE, THE HAPPY ONES" : 'KAMI YANG BERBAHAGIA' }}</h2></div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-6fb722a animated-slow wdp-sticky-section-no elementor-widget elementor-widget-heading animated fadeInUp"
                                                                        data-id="6fb722a"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <h2 class="elementor-heading-title elementor-size-default" v-if="client.hajat === 'wanita'" style="color: #79a8a9">
                                                                                {{  client.nama_wanita }} <span style="font-family: Analogue, Times, serif !important; font-size: 25px; font-style: italic; color: #79a8a9">&amp;</span> {{ client.nama_pria }}
                                                                            </h2>
                                                                            <h2 class="elementor-heading-title elementor-size-default" v-else  style="color: #79a8a9">
                                                                                {{  client.nama_pria }} <span style="font-family: Analogue, Times, serif !important; font-size: 25px; font-style: italic; color: #79a8a9">&amp;</span> {{ client.nama_wanita }}
                                                                            </h2>
                                                                        </div>
                                                                        <!-- HASHTAG -->
                                                                        <!-- <div class="elementor-widget-container">
                                                                           <p style="font-style:italic; font-family: Arima Madurai, Sans-serif; color: white; font-size: 12px; padding-top: 5px">#SESAyangADIEN</p>
                                                                        </div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section
                                                data-dce-background-color="#A99D87"
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-f3e09e2 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default wdp-sticky-section-no"
                                                data-id="f3e09e2"
                                                data-element_type="section"
                                                data-settings='{"background_background":"classic","_ha_eqh_enable":false}'
                                            >
                                                <div class="elementor-container elementor-column-gap-default">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-23d8bb0 wdp-sticky-section-no" data-id="23d8bb0" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <section
                                                                        class="elementor-section elementor-inner-section elementor-element elementor-element-cb19f87 animated-slow elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no elementor-invisible"
                                                                        data-id="cb19f87"
                                                                        data-element_type="section"
                                                                        data-settings='{"animation":"fadeInDown","_ha_eqh_enable":false}'
                                                                    >
                                                                        <div class="elementor-container elementor-column-gap-default">
                                                                            <div class="elementor-row">
                                                                                <div
                                                                                    class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-6bbb1dc wdp-sticky-section-no"
                                                                                    data-id="6bbb1dc"
                                                                                    data-element_type="column"
                                                                                >
                                                                                    <div class="elementor-column-wrap elementor-element-populated">
                                                                                        <div class="elementor-widget-wrap">
                                                                                            <div
                                                                                            class="elementor-element elementor-element-0522a5f elementor-view-framed elementor-widget__width-auto elementor-shape-circle wdp-sticky-section-no elementor-widget elementor-widget-icon"
                                                                                            data-id="0522a5f"
                                                                                            data-element_type="widget"
                                                                                            data-widget_type="icon.default"
                                                                                            >
                                                                                                <div class="elementor-widget-container">
                                                                                                    <div class="elementor-icon-wrapper">
                                                                                                    <div class="elementor-icon">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" id="e6452766-98c1-4deb-a691-b255b3232226" data-name="Layer 1" viewBox="0 0 50 59.56">
                                                                                                        <defs></defs>
                                                                                                        <title>logo-icon</title>
                                                                                                        <image xlink:href="assets/images/logo.png" width="50" height="59.56" />
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div
                                                                                                class="elementor-element elementor-element-2e933f1 animated-slow elementor-widget__width-auto wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                                                data-id="2e933f1"
                                                                                                data-element_type="widget"
                                                                                                data-settings='{"_animation":"none"}'
                                                                                                data-widget_type="heading.default"
                                                                                            >
                                                                                                <div class="elementor-widget-container"><p class="elementor-heading-title elementor-size-default">itsyourday.com</p></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                    <div
                                                                        class="elementor-element elementor-element-97fd3cb animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-heading"
                                                                        data-id="97fd3cb"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="heading.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <p class="elementor-heading-title elementor-size-default">
                                                                                WA: 0882 9060 1583 | IG: @itsyourday_digitalinvitation<br />
                                                                                Copyright © 2024
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="elementor-element elementor-element-a330d8d elementor-widget__width-auto animated-slow wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-button"
                                                                        data-id="a330d8d"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"fadeInUp"}'
                                                                        data-widget_type="button.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <div class="elementor-button-wrapper">
                                                                                <a
                                                                                    href="https://api.whatsapp.com/send?phone=6288290601583&#038;text=Hai%20kak%20Mini%2c%20saya%20ingin%20tanya%20tentang%20Undangan%20Digital"
                                                                                    class="elementor-button-link elementor-button elementor-size-sm elementor-animation-grow"
                                                                                    role="button"
                                                                                    target="_blank"
                                                                                >
                                                                                    <span class="elementor-button-content-wrapper"> <span class="elementor-button-text">{{ config.language === 1 ? "CONTACT US" : 'HUBUNGI KAMI' }}</span> </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <section
                                                class="elementor-section elementor-inner-section elementor-element elementor-element-89ac2e9 elementor-section-content-middle wdp-sticky-section-yes elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-positon-bottom"
                                                data-id="89ac2e9"
                                                data-element_type="section"
                                                data-settings='{"sticky":"bottom","_ha_eqh_enable":false,"sticky_on":["desktop","tablet","mobile"],"sticky_offset":0,"sticky_effects_offset":0}'
                                            >
                                                <div class="elementor-container elementor-column-gap-no">
                                                    <div class="elementor-row">
                                                        <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-59a0679 wdp-sticky-section-no" data-id="59a0679" data-element_type="column">
                                                            <div class="elementor-column-wrap elementor-element-populated">
                                                                <div class="elementor-widget-wrap">
                                                                    <div
                                                                        class="elementor-element elementor-element-e32d3eb elementor-view-stacked animated-slow elementor-widget__width-auto btn_musik elementor-shape-circle wdp-sticky-section-no elementor-invisible elementor-widget elementor-widget-weddingpress-audio"
                                                                        data-id="e32d3eb"
                                                                        data-element_type="widget"
                                                                        data-settings='{"_animation":"zoomIn","_animation_delay":2000}'
                                                                        data-widget_type="weddingpress-audio.default"
                                                                    >
                                                                        <div class="elementor-widget-container">
                                                                            <div id="audio-container" class="audio-box">
                                                                                <audio id="song" :src="'https://admin.itsyourdayofficial.com/song/' + audio" loop>
                                                                                    <source :src="'https://admin.itsyourdayofficial.com/song/' + audio" type="audio/mp3" />
                                                                                </audio>
                                                                                <div class="elementor-icon-wrapper" id="unmute-sound" style="display: none;">
                                                                                    <div class="elementor-icon elementor-animation-shrink">
                                                                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                                                            width="1080.000000pt" height="1080.000000pt" viewBox="0 0 1080.000000 1080.000000"
                                                                                            preserveAspectRatio="xMidYMid meet">
                                                                                            <metadata>
                                                                                            Created by potrace 1.16, written by Peter Selinger 2001-2019
                                                                                            </metadata>
                                                                                            <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
                                                                                            fill="#000000" stroke="none">
                                                                                            <path d="M5140 10794 c-604 -40 -1065 -131 -1561 -307 -447 -160 -929 -411
                                                                                            -1299 -678 -1061 -764 -1789 -1818 -2110 -3056 -235 -905 -224 -1909 31 -2819
                                                                                            205 -735 557 -1399 1064 -2009 134 -161 488 -516 655 -656 1263 -1062 2906
                                                                                            -1482 4545 -1163 666 129 1338 406 1913 788 317 210 541 395 833 686 448 447
                                                                                            768 891 1034 1435 298 608 473 1235 537 1930 19 206 16 748 -5 955 -73 705
                                                                                            -239 1292 -532 1885 -269 545 -581 980 -1010 1411 -221 222 -370 353 -578 510
                                                                                            -820 619 -1755 979 -2782 1074 -114 11 -638 21 -735 14z m749 -230 c842 -80
                                                                                            1640 -359 2341 -818 949 -622 1669 -1528 2050 -2581 388 -1072 409 -2269 59
                                                                                            -3352 -257 -794 -683 -1488 -1283 -2089 -416 -415 -847 -723 -1386 -988 -1431
                                                                                            -706 -3162 -697 -4590 25 -385 194 -733 426 -1060 705 -151 129 -442 419 -565
                                                                                            564 -703 826 -1117 1805 -1219 2880 -80 833 46 1691 360 2455 72 174 240 512
                                                                                            331 665 416 696 962 1267 1648 1722 619 410 1362 685 2140 792 101 14 301 31
                                                                                            465 40 126 7 555 -5 709 -20z"/>
                                                                                            <path d="M5000 10380 c-443 -42 -827 -121 -1210 -249 -609 -205 -1156 -517
                                                                                            -1660 -945 -143 -122 -455 -436 -571 -576 -730 -878 -1127 -1905 -1168 -3019
                                                                                            -32 -865 140 -1673 515 -2424 208 -415 442 -762 750 -1106 873 -979 2050
                                                                                            -1561 3361 -1662 197 -15 766 -6 933 15 616 79 1132 231 1652 487 649 321
                                                                                            1213 773 1679 1344 390 479 693 1039 875 1620 161 510 234 987 234 1531 0 252
                                                                                            -9 404 -41 649 -206 1618 -1231 3066 -2693 3806 -543 276 -1069 435 -1681 510
                                                                                            -136 17 -241 22 -525 24 -195 2 -398 -1 -450 -5z m462 -360 c3 -3 -4 -153 -15
                                                                                            -334 -22 -366 -20 -394 33 -421 44 -23 141 -20 173 5 39 31 47 77 47 280 0
                                                                                            102 3 248 6 325 l7 140 148 -5 c81 -3 151 -8 154 -12 9 -9 -22 -605 -36 -701
                                                                                            -22 -156 -112 -247 -278 -283 -175 -37 -370 1 -470 92 -80 71 -101 148 -90
                                                                                            324 4 58 7 205 8 326 1 122 4 234 7 250 l5 29 149 -5 c82 -3 150 -7 152 -10z
                                                                                            m-927 -105 c61 -10 151 -63 202 -119 84 -94 138 -237 150 -398 19 -267 -123
                                                                                            -452 -394 -512 -90 -20 -152 -20 -228 -1 -151 39 -265 173 -315 370 -80 314
                                                                                            23 539 291 632 110 38 184 45 294 28z m2196 -76 c272 -87 314 -105 374 -160
                                                                                            69 -63 89 -109 89 -199 0 -63 -4 -85 -30 -140 -38 -81 -99 -142 -176 -178 -69
                                                                                            -32 -69 -13 -1 -232 57 -184 55 -172 44 -190 -8 -12 -25 -13 -113 -3 -145 17
                                                                                            -206 31 -218 53 -11 20 -40 232 -40 291 0 42 -13 52 -80 66 -25 5 -27 2 -65
                                                                                            -134 -21 -76 -39 -140 -41 -142 -2 -3 -284 88 -292 94 -1 1 21 65 51 141 29
                                                                                            77 98 289 152 472 55 182 105 332 111 332 6 0 112 -32 235 -71z m-3176 -638
                                                                                            c6 -231 11 -313 21 -333 8 -16 46 -78 85 -139 38 -61 69 -113 67 -114 -9 -8
                                                                                            -257 -145 -262 -145 -3 0 -37 62 -76 138 l-70 137 -306 219 c-282 202 -305
                                                                                            220 -288 233 31 23 250 143 261 143 5 0 18 -10 29 -21 30 -33 275 -239 285
                                                                                            -239 5 0 9 8 9 18 0 20 -28 298 -36 362 l-6 45 134 75 133 76 6 -74 c4 -41 10
                                                                                            -212 14 -381z m4379 94 c197 -129 269 -193 312 -282 26 -52 29 -68 29 -158 0
                                                                                            -85 -4 -109 -27 -162 -74 -175 -221 -326 -364 -373 -55 -19 -82 -22 -158 -18
                                                                                            -144 8 -213 41 -462 221 -90 66 -134 104 -130 113 3 8 45 67 94 131 48 65 170
                                                                                            247 271 405 l184 286 36 -22 c20 -13 117 -76 215 -141z m-5392 -452 c81 -130
                                                                                            80 -138 -20 -169 -130 -39 -201 -96 -188 -149 11 -42 44 -41 193 6 223 70 318
                                                                                            60 411 -46 56 -64 76 -127 70 -226 -6 -105 -47 -188 -137 -279 -94 -95 -281
                                                                                            -215 -301 -193 -4 4 -39 54 -78 111 -82 119 -83 137 -14 147 113 17 249 89
                                                                                            272 145 11 27 10 33 -5 50 -26 29 -58 25 -197 -21 -200 -67 -308 -55 -394 44
                                                                                            -96 111 -108 253 -32 401 25 49 127 157 188 199 56 39 142 87 155 87 6 0 40
                                                                                            -48 77 -107z m3346 -282 c610 -106 1116 -335 1557 -705 95 -80 310 -293 389
                                                                                            -386 422 -499 675 -1088 745 -1742 14 -125 14 -515 1 -649 -23 -224 -80 -491
                                                                                            -149 -691 -16 -48 -26 -90 -22 -92 4 -3 337 -127 741 -275 403 -149 736 -273
                                                                                            738 -276 10 -10 -102 -273 -198 -470 -229 -466 -502 -850 -875 -1234 -623
                                                                                            -639 -1399 -1086 -2271 -1305 -242 -61 -553 -109 -819 -127 -210 -14 -671 -6
                                                                                            -853 15 -881 102 -1663 411 -2357 932 -212 159 -353 284 -560 496 -353 361
                                                                                            -630 749 -853 1193 -122 244 -220 481 -205 495 4 4 343 130 753 280 410 150
                                                                                            746 274 747 274 1 1 -14 49 -33 106 -49 146 -100 360 -125 520 -27 169 -38
                                                                                            619 -20 793 49 477 201 934 442 1334 429 712 1125 1234 1926 1443 313 82 474
                                                                                            100 863 96 267 -3 334 -7 438 -25z m3112 -203 c62 -72 114 -137 117 -144 5
                                                                                            -13 -565 -853 -578 -854 -7 0 -199 225 -199 233 0 3 24 34 54 68 l54 63 -45
                                                                                            56 c-58 70 -132 150 -140 150 -4 0 -38 -18 -75 -41 l-68 -40 -24 28 c-13 15
                                                                                            -56 66 -95 112 -39 47 -68 88 -64 92 10 10 924 407 940 408 6 1 62 -58 123
                                                                                            -131z m-7187 -204 c48 -37 87 -71 87 -75 0 -5 -28 -47 -61 -94 -34 -47 -60
                                                                                            -87 -58 -89 48 -37 382 -256 489 -321 80 -48 146 -88 147 -89 1 -1 -36 -59
                                                                                            -83 -129 -56 -85 -89 -125 -97 -121 -7 4 -59 42 -117 85 -87 64 -430 287 -491
                                                                                            320 -15 8 -25 -4 -68 -80 -28 -49 -58 -94 -66 -101 -17 -14 -16 -14 -122 67
                                                                                            l-82 64 116 177 c64 97 156 239 206 315 49 75 94 137 101 137 6 0 51 -30 99
                                                                                            -66z m7727 -567 l64 -138 -142 -142 c-78 -78 -142 -148 -142 -155 0 -9 46 -11
                                                                                            213 -6 l212 6 63 -138 c34 -75 61 -139 59 -141 -2 -2 -176 5 -387 14 l-384 17
                                                                                            -138 -72 -139 -72 -28 62 c-16 35 -45 98 -65 140 -19 43 -32 79 -28 81 4 3 67
                                                                                            28 140 57 l133 52 247 294 c136 161 249 290 253 286 3 -4 34 -69 69 -145z
                                                                                            m-8075 -489 c171 -75 372 -157 448 -184 75 -27 137 -54 137 -60 0 -15 -99
                                                                                            -257 -110 -268 -5 -5 -67 19 -141 53 -73 34 -246 105 -384 158 -137 52 -285
                                                                                            108 -327 124 l-77 30 51 122 c27 67 54 133 59 146 5 12 15 21 22 18 6 -3 152
                                                                                            -65 322 -139z"/>
                                                                                            <path d="M4318 9654 c-93 -29 -109 -98 -64 -275 62 -241 111 -294 238 -258
                                                                                            101 27 118 94 73 275 -36 146 -74 228 -117 254 -38 23 -65 24 -130 4z"/>
                                                                                            <path d="M6672 9493 c-23 -64 -39 -119 -37 -123 2 -4 28 -15 58 -25 50 -17 55
                                                                                            -17 88 -1 71 33 109 156 64 206 -21 24 -90 59 -116 60 -12 0 -28 -32 -57 -117z"/>
                                                                                            <path d="M7729 8978 c-161 -226 -219 -311 -219 -324 0 -21 111 -103 147 -110
                                                                                            49 -9 79 2 130 49 89 82 183 248 183 324 0 51 -31 92 -104 133 -28 17 -54 30
                                                                                            -58 30 -3 0 -39 -46 -79 -102z"/>
                                                                                            <path d="M5430 6813 c-352 -19 -621 -133 -764 -325 -48 -64 -76 -161 -76 -263
                                                                                            0 -104 15 -168 59 -255 91 -178 255 -340 615 -604 340 -250 482 -377 567 -509
                                                                                            106 -164 90 -346 -44 -477 -29 -28 -79 -64 -112 -81 l-60 -29 55 6 c395 42
                                                                                            725 261 796 529 18 66 17 185 -1 263 -30 125 -106 241 -257 388 -116 113 -198
                                                                                            179 -408 329 -221 158 -291 214 -380 305 -89 90 -144 170 -171 245 -35 99 -19
                                                                                            237 36 325 37 58 122 120 195 142 24 7 -3 13 -50 11z"/>
                                                                                            <path d="M6270 6793 c-65 -30 -294 -28 -485 3 -20 3 -11 -4 26 -19 129 -56
                                                                                            242 -148 339 -278 60 -81 131 -216 149 -286 7 -24 14 -43 16 -43 3 0 5 144 5
                                                                                            320 0 176 -3 320 -7 319 -5 0 -24 -8 -43 -16z"/>
                                                                                            <path d="M4913 5280 c-116 -17 -232 -106 -287 -220 -29 -60 -31 -73 -31 -171
                                                                                            0 -102 1 -108 38 -182 84 -169 249 -295 494 -376 48 -16 111 -32 138 -36 l50
                                                                                            -6 -46 25 c-70 38 -119 98 -119 145 0 41 12 61 99 163 85 99 106 153 106 273
                                                                                            0 89 -3 107 -28 160 -45 96 -144 185 -237 213 -52 15 -119 20 -177 12z"/>
                                                                                            <path d="M8472 3888 c-43 -73 -116 -217 -112 -221 3 -2 78 -45 167 -97 90 -51
                                                                                            160 -97 157 -102 -4 -6 -52 -7 -123 -4 -64 4 -166 9 -227 12 l-110 6 -89 -146
                                                                                            c-48 -81 -86 -148 -84 -150 2 -2 123 -73 269 -158 146 -85 305 -182 354 -217
                                                                                            49 -34 94 -65 101 -67 7 -3 40 41 84 111 39 64 71 117 71 119 0 2 -69 43 -152
                                                                                            90 -168 95 -195 113 -186 122 4 3 107 1 229 -4 123 -6 227 -9 230 -6 10 6 182
                                                                                            285 177 288 -1 2 -59 35 -128 74 -69 38 -233 140 -363 226 -131 86 -240 156
                                                                                            -242 156 -2 0 -12 -15 -23 -32z"/>
                                                                                            <path d="M2090 3763 c-135 -75 -303 -163 -375 -196 -71 -32 -131 -60 -132 -61
                                                                                            -4 -4 138 -276 143 -276 3 0 58 31 123 69 65 39 232 125 372 191 140 67 258
                                                                                            126 263 130 9 9 -123 272 -139 277 -5 2 -120 -59 -255 -134z"/>
                                                                                            <path d="M2354 3253 c-121 -109 -268 -235 -327 -281 -67 -53 -105 -88 -100
                                                                                            -95 17 -28 167 -197 175 -197 5 0 72 58 150 130 77 71 144 127 149 125 8 -5
                                                                                            -5 -44 -105 -314 l-46 -124 23 -26 c12 -15 62 -73 111 -129 l89 -103 36 33
                                                                                            c20 18 65 60 101 94 36 34 166 144 290 244 124 99 226 186 228 193 1 7 -42 55
                                                                                            -95 109 l-98 96 -140 -128 c-77 -71 -143 -125 -148 -121 -4 4 27 99 68 211 41
                                                                                            113 75 210 75 218 0 11 -123 162 -200 245 -16 17 -27 9 -236 -180z"/>
                                                                                            <path d="M7866 2959 c-75 -18 -160 -72 -236 -149 -156 -156 -188 -318 -99
                                                                                            -499 45 -91 163 -212 248 -254 61 -30 73 -32 166 -32 97 0 102 1 172 38 95 51
                                                                                            202 161 246 253 29 64 32 77 32 169 0 95 -2 104 -37 176 -53 109 -157 218
                                                                                            -251 264 -79 39 -171 52 -241 34z m76 -309 c41 -27 121 -111 176 -184 12 -16
                                                                                            22 -42 22 -57 0 -56 -91 -127 -145 -113 -34 9 -132 100 -190 176 -57 75 -59
                                                                                            111 -12 155 65 62 86 65 149 23z"/>
                                                                                            <path d="M3058 2313 l-135 -428 156 -102 c86 -57 158 -103 161 -103 13 0 690
                                                                                            566 688 575 -2 5 -57 45 -122 88 l-120 79 -67 -73 c-78 -87 -367 -346 -377
                                                                                            -339 -4 3 -6 10 -4 17 3 12 177 441 206 505 l16 38 -130 85 c-72 47 -132 85
                                                                                            -133 85 -2 0 -64 -192 -139 -427z"/>
                                                                                            <path d="M7060 2389 c-69 -39 -129 -75 -133 -79 -4 -4 54 -110 129 -236 76
                                                                                            -126 172 -295 213 -375 l76 -147 130 74 c71 40 131 77 133 82 1 5 -32 64 -75
                                                                                            133 -43 68 -136 235 -207 372 -71 136 -132 247 -135 247 -3 0 -62 -32 -131
                                                                                            -71z"/>
                                                                                            <path d="M4040 2235 c0 -18 -221 -610 -258 -691 -28 -60 -47 -112 -44 -116 9
                                                                                            -9 263 -108 277 -108 6 0 30 60 54 133 24 72 92 247 152 388 59 141 109 262
                                                                                            111 269 2 8 -50 33 -144 70 -82 32 -148 57 -148 55z"/>
                                                                                            <path d="M6460 2123 c-173 -58 -316 -107 -318 -108 -1 -1 10 -52 25 -114 18
                                                                                            -75 32 -113 43 -116 9 -3 56 11 104 30 49 20 89 35 91 33 10 -12 137 -394 160
                                                                                            -480 15 -60 33 -108 39 -108 13 0 277 87 283 93 2 2 -19 63 -47 136 -48 129
                                                                                            -153 452 -148 456 2 2 39 12 83 24 44 12 85 23 92 26 8 2 4 34 -14 107 -28
                                                                                            111 -36 128 -62 127 -9 0 -158 -48 -331 -106z"/>
                                                                                            <path d="M4445 1949 c-20 -78 -25 -113 -17 -121 6 -6 50 -15 99 -21 48 -5 91
                                                                                            -13 96 -17 10 -9 -35 -290 -74 -463 -18 -79 -28 -142 -23 -146 5 -5 73 -20
                                                                                            151 -33 l142 -25 5 26 c3 14 10 64 16 111 11 82 81 471 87 479 1 2 41 -6 87
                                                                                            -18 47 -11 89 -21 94 -21 10 0 62 184 62 218 0 14 -8 24 -22 27 -44 10 -642
                                                                                            115 -658 115 -12 0 -23 -27 -45 -111z"/>
                                                                                            <path d="M5650 1963 c-96 -7 -177 -18 -180 -23 -9 -15 -204 -869 -199 -873 2
                                                                                            -2 69 2 149 7 l145 11 9 61 8 61 61 7 c33 3 84 6 113 6 57 0 57 0 70 -72 l6
                                                                                            -38 52 0 c62 1 257 18 263 23 7 8 -301 847 -312 846 -5 -1 -89 -8 -185 -16z
                                                                                            m65 -395 c32 -113 32 -113 11 -120 -33 -10 -106 -9 -106 1 0 18 42 225 47 234
                                                                                            9 14 14 1 48 -115z"/>
                                                                                            <path d="M8632 8112 c-79 -38 -143 -72 -142 -78 1 -12 91 -124 99 -124 8 1
                                                                                            189 234 197 254 3 9 2 16 -2 16 -5 0 -74 -31 -152 -68z"/>
                                                                                            </g>
                                                                                            </svg>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="elementor-icon-wrapper" id="mute-sound" >
                                                                                    <div class="elementor-icon elementor-animation-shrink">
                                                                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                                                                width="1080.000000pt" height="1080.000000pt" viewBox="0 0 1080.000000 1080.000000"
                                                                                                preserveAspectRatio="xMidYMid meet">
                                                                                                <metadata>
                                                                                                Created by potrace 1.16, written by Peter Selinger 2001-2019
                                                                                                </metadata>
                                                                                                <g transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
                                                                                                fill="#000000" stroke="none">
                                                                                                <path d="M5140 10794 c-604 -40 -1065 -131 -1561 -307 -447 -160 -929 -411
                                                                                                -1299 -678 -1061 -764 -1789 -1818 -2110 -3056 -235 -905 -224 -1909 31 -2819
                                                                                                205 -735 557 -1399 1064 -2009 134 -161 488 -516 655 -656 1263 -1062 2906
                                                                                                -1482 4545 -1163 666 129 1338 406 1913 788 317 210 541 395 833 686 448 447
                                                                                                768 891 1034 1435 298 608 473 1235 537 1930 19 206 16 748 -5 955 -73 705
                                                                                                -239 1292 -532 1885 -269 545 -581 980 -1010 1411 -221 222 -370 353 -578 510
                                                                                                -820 619 -1755 979 -2782 1074 -114 11 -638 21 -735 14z m749 -230 c842 -80
                                                                                                1640 -359 2341 -818 949 -622 1669 -1528 2050 -2581 388 -1072 409 -2269 59
                                                                                                -3352 -257 -794 -683 -1488 -1283 -2089 -416 -415 -847 -723 -1386 -988 -1431
                                                                                                -706 -3162 -697 -4590 25 -385 194 -733 426 -1060 705 -151 129 -442 419 -565
                                                                                                564 -703 826 -1117 1805 -1219 2880 -80 833 46 1691 360 2455 72 174 240 512
                                                                                                331 665 416 696 962 1267 1648 1722 619 410 1362 685 2140 792 101 14 301 31
                                                                                                465 40 126 7 555 -5 709 -20z"/>
                                                                                                <path d="M5000 10380 c-443 -42 -827 -121 -1210 -249 -609 -205 -1156 -517
                                                                                                -1660 -945 -143 -122 -455 -436 -571 -576 -730 -878 -1127 -1905 -1168 -3019
                                                                                                -32 -865 140 -1673 515 -2424 208 -415 442 -762 750 -1106 873 -979 2050
                                                                                                -1561 3361 -1662 197 -15 766 -6 933 15 616 79 1132 231 1652 487 649 321
                                                                                                1213 773 1679 1344 390 479 693 1039 875 1620 161 510 234 987 234 1531 0 252
                                                                                                -9 404 -41 649 -206 1618 -1231 3066 -2693 3806 -543 276 -1069 435 -1681 510
                                                                                                -136 17 -241 22 -525 24 -195 2 -398 -1 -450 -5z m462 -360 c3 -3 -4 -153 -15
                                                                                                -334 -22 -366 -20 -394 33 -421 44 -23 141 -20 173 5 39 31 47 77 47 280 0
                                                                                                102 3 248 6 325 l7 140 148 -5 c81 -3 151 -8 154 -12 9 -9 -22 -605 -36 -701
                                                                                                -22 -156 -112 -247 -278 -283 -175 -37 -370 1 -470 92 -80 71 -101 148 -90
                                                                                                324 4 58 7 205 8 326 1 122 4 234 7 250 l5 29 149 -5 c82 -3 150 -7 152 -10z
                                                                                                m-927 -105 c61 -10 151 -63 202 -119 84 -94 138 -237 150 -398 19 -267 -123
                                                                                                -452 -394 -512 -90 -20 -152 -20 -228 -1 -151 39 -265 173 -315 370 -80 314
                                                                                                23 539 291 632 110 38 184 45 294 28z m2196 -76 c272 -87 314 -105 374 -160
                                                                                                69 -63 89 -109 89 -199 0 -63 -4 -85 -30 -140 -38 -81 -99 -142 -176 -178 -69
                                                                                                -32 -69 -13 -1 -232 57 -184 55 -172 44 -190 -8 -12 -25 -13 -113 -3 -145 17
                                                                                                -206 31 -218 53 -11 20 -40 232 -40 291 0 42 -13 52 -80 66 -25 5 -27 2 -65
                                                                                                -134 -21 -76 -39 -140 -41 -142 -2 -3 -284 88 -292 94 -1 1 21 65 51 141 29
                                                                                                77 98 289 152 472 55 182 105 332 111 332 6 0 112 -32 235 -71z m-3176 -638
                                                                                                c6 -231 11 -313 21 -333 8 -16 46 -78 85 -139 38 -61 69 -113 67 -114 -9 -8
                                                                                                -257 -145 -262 -145 -3 0 -37 62 -76 138 l-70 137 -306 219 c-282 202 -305
                                                                                                220 -288 233 31 23 250 143 261 143 5 0 18 -10 29 -21 30 -33 275 -239 285
                                                                                                -239 5 0 9 8 9 18 0 20 -28 298 -36 362 l-6 45 134 75 133 76 6 -74 c4 -41 10
                                                                                                -212 14 -381z m4379 94 c197 -129 269 -193 312 -282 26 -52 29 -68 29 -158 0
                                                                                                -85 -4 -109 -27 -162 -74 -175 -221 -326 -364 -373 -55 -19 -82 -22 -158 -18
                                                                                                -144 8 -213 41 -462 221 -90 66 -134 104 -130 113 3 8 45 67 94 131 48 65 170
                                                                                                247 271 405 l184 286 36 -22 c20 -13 117 -76 215 -141z m-5392 -452 c81 -130
                                                                                                80 -138 -20 -169 -130 -39 -201 -96 -188 -149 11 -42 44 -41 193 6 223 70 318
                                                                                                60 411 -46 56 -64 76 -127 70 -226 -6 -105 -47 -188 -137 -279 -94 -95 -281
                                                                                                -215 -301 -193 -4 4 -39 54 -78 111 -82 119 -83 137 -14 147 113 17 249 89
                                                                                                272 145 11 27 10 33 -5 50 -26 29 -58 25 -197 -21 -200 -67 -308 -55 -394 44
                                                                                                -96 111 -108 253 -32 401 25 49 127 157 188 199 56 39 142 87 155 87 6 0 40
                                                                                                -48 77 -107z m3346 -282 c610 -106 1116 -335 1557 -705 95 -80 310 -293 389
                                                                                                -386 422 -499 675 -1088 745 -1742 14 -125 14 -515 1 -649 -23 -224 -80 -491
                                                                                                -149 -691 -16 -48 -26 -90 -22 -92 4 -3 337 -127 741 -275 403 -149 736 -273
                                                                                                738 -276 10 -10 -102 -273 -198 -470 -229 -466 -502 -850 -875 -1234 -623
                                                                                                -639 -1399 -1086 -2271 -1305 -242 -61 -553 -109 -819 -127 -210 -14 -671 -6
                                                                                                -853 15 -881 102 -1663 411 -2357 932 -212 159 -353 284 -560 496 -353 361
                                                                                                -630 749 -853 1193 -122 244 -220 481 -205 495 4 4 343 130 753 280 410 150
                                                                                                746 274 747 274 1 1 -14 49 -33 106 -49 146 -100 360 -125 520 -27 169 -38
                                                                                                619 -20 793 49 477 201 934 442 1334 429 712 1125 1234 1926 1443 313 82 474
                                                                                                100 863 96 267 -3 334 -7 438 -25z m3112 -203 c62 -72 114 -137 117 -144 5
                                                                                                -13 -565 -853 -578 -854 -7 0 -199 225 -199 233 0 3 24 34 54 68 l54 63 -45
                                                                                                56 c-58 70 -132 150 -140 150 -4 0 -38 -18 -75 -41 l-68 -40 -24 28 c-13 15
                                                                                                -56 66 -95 112 -39 47 -68 88 -64 92 10 10 924 407 940 408 6 1 62 -58 123
                                                                                                -131z m-7187 -204 c48 -37 87 -71 87 -75 0 -5 -28 -47 -61 -94 -34 -47 -60
                                                                                                -87 -58 -89 48 -37 382 -256 489 -321 80 -48 146 -88 147 -89 1 -1 -36 -59
                                                                                                -83 -129 -56 -85 -89 -125 -97 -121 -7 4 -59 42 -117 85 -87 64 -430 287 -491
                                                                                                320 -15 8 -25 -4 -68 -80 -28 -49 -58 -94 -66 -101 -17 -14 -16 -14 -122 67
                                                                                                l-82 64 116 177 c64 97 156 239 206 315 49 75 94 137 101 137 6 0 51 -30 99
                                                                                                -66z m7727 -567 l64 -138 -142 -142 c-78 -78 -142 -148 -142 -155 0 -9 46 -11
                                                                                                213 -6 l212 6 63 -138 c34 -75 61 -139 59 -141 -2 -2 -176 5 -387 14 l-384 17
                                                                                                -138 -72 -139 -72 -28 62 c-16 35 -45 98 -65 140 -19 43 -32 79 -28 81 4 3 67
                                                                                                28 140 57 l133 52 247 294 c136 161 249 290 253 286 3 -4 34 -69 69 -145z
                                                                                                m-8075 -489 c171 -75 372 -157 448 -184 75 -27 137 -54 137 -60 0 -15 -99
                                                                                                -257 -110 -268 -5 -5 -67 19 -141 53 -73 34 -246 105 -384 158 -137 52 -285
                                                                                                108 -327 124 l-77 30 51 122 c27 67 54 133 59 146 5 12 15 21 22 18 6 -3 152
                                                                                                -65 322 -139z"/>
                                                                                                <path d="M4318 9654 c-93 -29 -109 -98 -64 -275 62 -241 111 -294 238 -258
                                                                                                101 27 118 94 73 275 -36 146 -74 228 -117 254 -38 23 -65 24 -130 4z"/>
                                                                                                <path d="M6672 9493 c-23 -64 -39 -119 -37 -123 2 -4 28 -15 58 -25 50 -17 55
                                                                                                -17 88 -1 71 33 109 156 64 206 -21 24 -90 59 -116 60 -12 0 -28 -32 -57 -117z"/>
                                                                                                <path d="M7729 8978 c-161 -226 -219 -311 -219 -324 0 -21 111 -103 147 -110
                                                                                                49 -9 79 2 130 49 89 82 183 248 183 324 0 51 -31 92 -104 133 -28 17 -54 30
                                                                                                -58 30 -3 0 -39 -46 -79 -102z"/>
                                                                                                <path d="M5430 6813 c-352 -19 -621 -133 -764 -325 -48 -64 -76 -161 -76 -263
                                                                                                0 -104 15 -168 59 -255 91 -178 255 -340 615 -604 340 -250 482 -377 567 -509
                                                                                                106 -164 90 -346 -44 -477 -29 -28 -79 -64 -112 -81 l-60 -29 55 6 c395 42
                                                                                                725 261 796 529 18 66 17 185 -1 263 -30 125 -106 241 -257 388 -116 113 -198
                                                                                                179 -408 329 -221 158 -291 214 -380 305 -89 90 -144 170 -171 245 -35 99 -19
                                                                                                237 36 325 37 58 122 120 195 142 24 7 -3 13 -50 11z"/>
                                                                                                <path d="M6270 6793 c-65 -30 -294 -28 -485 3 -20 3 -11 -4 26 -19 129 -56
                                                                                                242 -148 339 -278 60 -81 131 -216 149 -286 7 -24 14 -43 16 -43 3 0 5 144 5
                                                                                                320 0 176 -3 320 -7 319 -5 0 -24 -8 -43 -16z"/>
                                                                                                <path d="M4913 5280 c-116 -17 -232 -106 -287 -220 -29 -60 -31 -73 -31 -171
                                                                                                0 -102 1 -108 38 -182 84 -169 249 -295 494 -376 48 -16 111 -32 138 -36 l50
                                                                                                -6 -46 25 c-70 38 -119 98 -119 145 0 41 12 61 99 163 85 99 106 153 106 273
                                                                                                0 89 -3 107 -28 160 -45 96 -144 185 -237 213 -52 15 -119 20 -177 12z"/>
                                                                                                <path d="M8472 3888 c-43 -73 -116 -217 -112 -221 3 -2 78 -45 167 -97 90 -51
                                                                                                160 -97 157 -102 -4 -6 -52 -7 -123 -4 -64 4 -166 9 -227 12 l-110 6 -89 -146
                                                                                                c-48 -81 -86 -148 -84 -150 2 -2 123 -73 269 -158 146 -85 305 -182 354 -217
                                                                                                49 -34 94 -65 101 -67 7 -3 40 41 84 111 39 64 71 117 71 119 0 2 -69 43 -152
                                                                                                90 -168 95 -195 113 -186 122 4 3 107 1 229 -4 123 -6 227 -9 230 -6 10 6 182
                                                                                                285 177 288 -1 2 -59 35 -128 74 -69 38 -233 140 -363 226 -131 86 -240 156
                                                                                                -242 156 -2 0 -12 -15 -23 -32z"/>
                                                                                                <path d="M2090 3763 c-135 -75 -303 -163 -375 -196 -71 -32 -131 -60 -132 -61
                                                                                                -4 -4 138 -276 143 -276 3 0 58 31 123 69 65 39 232 125 372 191 140 67 258
                                                                                                126 263 130 9 9 -123 272 -139 277 -5 2 -120 -59 -255 -134z"/>
                                                                                                <path d="M2354 3253 c-121 -109 -268 -235 -327 -281 -67 -53 -105 -88 -100
                                                                                                -95 17 -28 167 -197 175 -197 5 0 72 58 150 130 77 71 144 127 149 125 8 -5
                                                                                                -5 -44 -105 -314 l-46 -124 23 -26 c12 -15 62 -73 111 -129 l89 -103 36 33
                                                                                                c20 18 65 60 101 94 36 34 166 144 290 244 124 99 226 186 228 193 1 7 -42 55
                                                                                                -95 109 l-98 96 -140 -128 c-77 -71 -143 -125 -148 -121 -4 4 27 99 68 211 41
                                                                                                113 75 210 75 218 0 11 -123 162 -200 245 -16 17 -27 9 -236 -180z"/>
                                                                                                <path d="M7866 2959 c-75 -18 -160 -72 -236 -149 -156 -156 -188 -318 -99
                                                                                                -499 45 -91 163 -212 248 -254 61 -30 73 -32 166 -32 97 0 102 1 172 38 95 51
                                                                                                202 161 246 253 29 64 32 77 32 169 0 95 -2 104 -37 176 -53 109 -157 218
                                                                                                -251 264 -79 39 -171 52 -241 34z m76 -309 c41 -27 121 -111 176 -184 12 -16
                                                                                                22 -42 22 -57 0 -56 -91 -127 -145 -113 -34 9 -132 100 -190 176 -57 75 -59
                                                                                                111 -12 155 65 62 86 65 149 23z"/>
                                                                                                <path d="M3058 2313 l-135 -428 156 -102 c86 -57 158 -103 161 -103 13 0 690
                                                                                                566 688 575 -2 5 -57 45 -122 88 l-120 79 -67 -73 c-78 -87 -367 -346 -377
                                                                                                -339 -4 3 -6 10 -4 17 3 12 177 441 206 505 l16 38 -130 85 c-72 47 -132 85
                                                                                                -133 85 -2 0 -64 -192 -139 -427z"/>
                                                                                                <path d="M7060 2389 c-69 -39 -129 -75 -133 -79 -4 -4 54 -110 129 -236 76
                                                                                                -126 172 -295 213 -375 l76 -147 130 74 c71 40 131 77 133 82 1 5 -32 64 -75
                                                                                                133 -43 68 -136 235 -207 372 -71 136 -132 247 -135 247 -3 0 -62 -32 -131
                                                                                                -71z"/>
                                                                                                <path d="M4040 2235 c0 -18 -221 -610 -258 -691 -28 -60 -47 -112 -44 -116 9
                                                                                                -9 263 -108 277 -108 6 0 30 60 54 133 24 72 92 247 152 388 59 141 109 262
                                                                                                111 269 2 8 -50 33 -144 70 -82 32 -148 57 -148 55z"/>
                                                                                                <path d="M6460 2123 c-173 -58 -316 -107 -318 -108 -1 -1 10 -52 25 -114 18
                                                                                                -75 32 -113 43 -116 9 -3 56 11 104 30 49 20 89 35 91 33 10 -12 137 -394 160
                                                                                                -480 15 -60 33 -108 39 -108 13 0 277 87 283 93 2 2 -19 63 -47 136 -48 129
                                                                                                -153 452 -148 456 2 2 39 12 83 24 44 12 85 23 92 26 8 2 4 34 -14 107 -28
                                                                                                111 -36 128 -62 127 -9 0 -158 -48 -331 -106z"/>
                                                                                                <path d="M4445 1949 c-20 -78 -25 -113 -17 -121 6 -6 50 -15 99 -21 48 -5 91
                                                                                                -13 96 -17 10 -9 -35 -290 -74 -463 -18 -79 -28 -142 -23 -146 5 -5 73 -20
                                                                                                151 -33 l142 -25 5 26 c3 14 10 64 16 111 11 82 81 471 87 479 1 2 41 -6 87
                                                                                                -18 47 -11 89 -21 94 -21 10 0 62 184 62 218 0 14 -8 24 -22 27 -44 10 -642
                                                                                                115 -658 115 -12 0 -23 -27 -45 -111z"/>
                                                                                                <path d="M5650 1963 c-96 -7 -177 -18 -180 -23 -9 -15 -204 -869 -199 -873 2
                                                                                                -2 69 2 149 7 l145 11 9 61 8 61 61 7 c33 3 84 6 113 6 57 0 57 0 70 -72 l6
                                                                                                -38 52 0 c62 1 257 18 263 23 7 8 -301 847 -312 846 -5 -1 -89 -8 -185 -16z
                                                                                                m65 -395 c32 -113 32 -113 11 -120 -33 -10 -106 -9 -106 1 0 18 42 225 47 234
                                                                                                9 14 14 1 48 -115z"/>
                                                                                                <path d="M8632 8112 c-79 -38 -143 -72 -142 -78 1 -12 91 -124 99 -124 8 1
                                                                                                189 234 197 254 3 9 2 16 -2 16 -5 0 -74 -31 -152 -68z"/>
                                                                                                </g>
                                                                                                </svg>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section
                        class="elementor-section elementor-top-section elementor-element elementor-element-a9399bf elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                        data-id="a9399bf"
                        data-element_type="section"
                        data-settings='{"_ha_eqh_enable":false}'
                    >
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2f117e4 wdp-sticky-section-no" data-id="2f117e4" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div class="elementor-element elementor-element-5130abf wdp-sticky-section-no elementor-widget elementor-widget-html" data-id="5130abf" data-element_type="widget" data-widget_type="html.default">
                                                <div class="elementor-widget-container"></div>
                                            </div>
                                            <div class="elementor-element elementor-element-a5d8c36 wdp-sticky-section-no elementor-widget elementor-widget-html" data-id="a5d8c36" data-element_type="widget" data-widget_type="html.default">
                                                <div class="elementor-widget-container"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div
            data-elementor-type="popup"
            data-elementor-id="50158"
            data-post-id="172600"
            data-obj-id="172600"
            class="elementor elementor-50158 dce-elementor-post-172600 elementor-location-popup"
            data-elementor-settings='{"entrance_animation":"fadeIn","exit_animation":"fadeIn","entrance_animation_duration":{"unit":"px","size":0.5,"sizes":[]},"triggers":{"page_load":"yes","page_load_delay":0},"timing":{"times_times":1,"times":"yes"}}'
        >
            <div class="elementor-section-wrap">
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-2533689 elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                    data-id="2533689"
                    data-element_type="section"
                    data-settings='{"_ha_eqh_enable":false}'
                >
                    <div class="elementor-container elementor-column-gap-default">
                        <div class="elementor-row">
                            <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-131bef9 wdp-sticky-section-no" data-id="131bef9" data-element_type="column">
                                <div class="elementor-column-wrap elementor-element-populated">
                                    <div class="elementor-widget-wrap">
                                        <div
                                            class="elementor-element elementor-element-093b077 elementor-aspect-ratio-11 elementor-widget__width-initial dce_masking-none wdp-sticky-section-no elementor-widget elementor-widget-video"
                                            data-id="093b077"
                                            data-element_type="widget"
                                            data-settings='{"video_type":"hosted","autoplay":"yes","play_on_mobile":"yes","mute":"yes","loop":"yes","aspect_ratio":"11"}'
                                            data-widget_type="video.default"
                                        >
                                            <div class="elementor-widget-container">
                                                <div class="e-hosted-video elementor-wrapper elementor-fit-aspect-ratio elementor-open-inline">
                                                    <video
                                                        class="elementor-video"
                                                        src="assets/wp-content/uploads/2022/08/81949-toggle-dark-mode-light-mode-themes.mp4"
                                                        controlsList="nodownload"
                                                    ></video>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="elementor-element elementor-element-7075053 wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                            data-id="7075053"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div class="elementor-widget-container">
                                                <h2 class="elementor-heading-title elementor-size-default">
                                                    Untuk mendapatkan tampilan terbaik <b>nonaktifkan dark mode / mode gelap</b><br />
                                                    dan gunakan <b>chrome</b> atau <b>safari</b>.
                                                </h2>
                                            </div>
                                        </div>
                                        <div
                                            class="elementor-element elementor-element-6623270 elementor-align-center elementor-widget__width-auto wdp-sticky-section-no elementor-widget elementor-widget-button"
                                            data-id="6623270"
                                            data-element_type="widget"
                                            data-widget_type="button.default"
                                        >
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a
                                                        href="#elementor-action%3Aaction%3Dpopup%3Aclose%26settings%3DeyJkb19ub3Rfc2hvd19hZ2FpbiI6InllcyJ9"
                                                        class="elementor-button-link elementor-button elementor-size-sm elementor-animation-shrink"
                                                        role="button"
                                                    >
                                                        <span class="elementor-button-content-wrapper"> <span class="elementor-button-text">Saya Mengerti</span> </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div
            data-elementor-type="popup"
            data-elementor-id="616"
            data-post-id="172600"
            data-obj-id="172600"
            class="elementor elementor-616 dce-elementor-post-172600 elementor-location-popup"
            data-elementor-settings='{"entrance_animation":"fadeIn","exit_animation":"fadeIn","prevent_scroll":"yes","entrance_animation_duration":{"unit":"px","size":0.8,"sizes":[]},"triggers":[],"timing":{"devices":"yes","devices_devices":["desktop","tablet","mobile"]}}'
        >
            <div class="elementor-section-wrap">
                <section
                    class="elementor-section elementor-top-section elementor-element elementor-element-213c45d8 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle wdp-sticky-section-no"
                    data-id="213c45d8"
                    data-element_type="section"
                    data-settings='{"_ha_eqh_enable":false}'
                >
                    <div class="elementor-container elementor-column-gap-default">
                        <div class="elementor-row">
                            <div
                                class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-175d12ff wdp-sticky-section-no"
                                data-id="175d12ff"
                                data-element_type="column"
                                data-settings='{"background_background":"classic"}'
                            >
                                <div class="elementor-column-wrap elementor-element-populated">
                                    <div class="elementor-widget-wrap">
                                        <div
                                            class="elementor-element elementor-element-d8834be wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                            data-id="d8834be"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div class="elementor-widget-container"><h2 class="elementor-heading-title elementor-size-default">- Nama -</h2></div>
                                        </div>
                                        <div
                                            class="elementor-element elementor-element-3ce7eba0 wdp-sticky-section-no elementor-widget elementor-widget-dce_barcode"
                                            data-id="3ce7eba0"
                                            data-element_type="widget"
                                            data-widget_type="dce_barcode.default"
                                        >
                                            <div class="elementor-widget-container">
                                                <img
                                                    class="dce-barcode dce-barcode-png"
                                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANIAAADSAQMAAAAFVwwBAAAABlBMVEX///8zMzM4VIyRAAAAAXRSTlMAQObYZgAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAN1JREFUWIXtlssNxCAMREeigJRE65REAUhe8AfIkg4GHwLx82lkj4F4NNhZnsgUULOCEU8Dck1itxH07FGlBpNmVSbhZZOJXHYyTZfL2t+MASmqjvnjY8uTi2n26dd0bMWZYWYiNTooh3AGCjWDTZZpthrKC3hZ12wcmhkXF87nj5f1lx78M9RzGb2UmG1OPBd5lDKzFeHJXbhc0/eu4mFrh6tS2y+4mclmHdSV0tV1voUJWYxXbHNZvXSZmfC+zbWXLptObMYsb80YWcyYG9D+7mFm04T1AKYVgZn9ANhUjeBpi4gjAAAAAElFTkSuQmCC"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="elementor-element elementor-element-d89ac53 wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                            data-id="d89ac53"
                                            data-element_type="widget"
                                            data-widget_type="heading.default"
                                        >
                                            <div class="elementor-widget-container">
                                                <h6 class="elementor-heading-title elementor-size-default"><center>Scan QR Code untuk check-in di lokasi.</center></h6>
                                            </div>
                                        </div>
                                        <section
                                            class="elementor-section elementor-inner-section elementor-element elementor-element-648a52e elementor-section-boxed elementor-section-height-default elementor-section-height-default wdp-sticky-section-no"
                                            data-id="648a52e"
                                            data-element_type="section"
                                            data-settings='{"_ha_eqh_enable":false}'
                                        >
                                            <div class="elementor-container elementor-column-gap-default">
                                                <div class="elementor-row">
                                                    <div class="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-af203c5 wdp-sticky-section-no" data-id="af203c5" data-element_type="column">
                                                        <div class="elementor-column-wrap elementor-element-populated">
                                                            <div class="elementor-widget-wrap">
                                                                <div
                                                                    class="elementor-element elementor-element-c717ebe elementor-view-framed elementor-widget__width-auto elementor-shape-circle wdp-sticky-section-no elementor-widget elementor-widget-icon"
                                                                    data-id="c717ebe"
                                                                    data-element_type="widget"
                                                                    data-widget_type="icon.default"
                                                                >
                                                                    <div class="elementor-widget-container">
                                                                        <div class="elementor-icon-wrapper">
                                                                            <div class="elementor-icon">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" id="e6452766-98c1-4deb-a691-b255b3232226" data-name="Layer 1" viewBox="0 0 50 59.56">
                                                                                    <defs></defs>
                                                                                    <title>logo-icon</title>
                                                                                    <path
                                                                                        class="af59355c-1891-45e0-9269-0f4994d98bb7"
                                                                                        d="M351.22,350.73a5.08,5.08,0,1,1,0,10.15,5.08,5.08,0,0,1,0-10.15Zm-20,37.19-8.15-22.44H306.65l.06,44.81h9.39l.77-17c.35-5.61.53-12.52.82-18.6h.12l8.86,13.23Zm15.05,22.37h9.86l-.06-44.81H340.48l-9,22.44h3.13L345,374.69h.23c0,6.56.18,13.05.42,18.42Zm-34.88-59.56a5.08,5.08,0,1,1-5.08,5.07,5.07,5.07,0,0,1,5.08-5.07Z"
                                                                                        transform="translate(-306.3 -350.73)"
                                                                                    ></path>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    class="elementor-element elementor-element-8f2b67f animated-slow elementor-widget__width-auto wdp-sticky-section-no elementor-widget elementor-widget-heading"
                                                                    data-id="8f2b67f"
                                                                    data-element_type="widget"
                                                                    data-settings='{"_animation":"none"}'
                                                                    data-widget_type="heading.default"
                                                                >
                                                                    <div class="elementor-widget-container"><p class="elementor-heading-title elementor-size-default"></p></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <transition name="slide-fade">
            <div id="snackbar" v-if="snackbar">{{ config.language === 1 ? 'Your confirmation has been saved, thank you.' : messageSnackBar }}</div>
        </transition>
    </div>
</template>

<script>
import moment from 'moment';
// import Modal from '../components/Modal.vue';
export default {
    name: 'HomePage',
    components: {
        // Modal,
    },
    data() {
        return {
            client: {},
            guest: {},
            gallery: [],
            img1: '',
            img2: '',
            img3: '',
            img4: '',
            moment: moment,
            // isModalVisible: true,
            playing: true,
            audio: '',
            errors: {},
            date: '',
            now: 0,
            count: 0,
            config: {},
            story: {
                title1: '',
                description1: '',
                date1: '',
                month1: '',
                year1: '',
                media1: '',
                type1: '',
                order1: '',
                rangeTime1: '',
                title2: '',
                description2: '',
                date2: '',
                month2: '',
                year2: '',
                media2: '',
                type2: '',
                order2: '',
                rangeTime2: '',
                title3: '',
                description3: '',
                date3: '',
                month3: '',
                year3: '',
                media3: '',
                type3: '',
                order3: '',
                rangeTime3: '',
                title4: '',
                description4: '',
                date4: '',
                month4: '',
                year4: '',
                media4: '',
                type4: '',
                order4: '',
                rangeTime4: '',
                title5: '',
                description5: '',
                date5: '',
                month5: '',
                year5: '',
                media5: '',
                type5: '',
                order5: '',
                rangeTime5: '',
            },
            name: '',
            picked: '',
            submit: {
                client_id: '',
                name: '',
                comment: '',
                confirmation: '',
                request_songs:'',
                artist: '',
            },
            hopes: {},
            hasPrev: false,
            hasNext: true,
            alsoInvite: {},
            showGallery: false,
            interval: null,
            isCopied: false,// Inisialisasi variabel untuk menentukan apakah teks telah disalin
            isCopied2: false,// Inisialisasi variabel untuk menentukan apakah teks telah disalin
            isCopied3: false,// Inisialisasi variabel untuk menentukan apakah teks telah disalin
            isCopied4: false,// Inisialisasi variabel untuk menentukan apakah teks telah disalin
            isCopied5: false,// Inisialisasi variabel untuk menentukan apakah teks telah disalin
            snackbar: false,
            messageSnackBar: 'Konfirmasi anda sudah tersimpan, terima kasih.',
        }
    },
    created() {
        // if (this.isModalVisible == true) {
        //     document.body.style.overflow = "hidden";
        //     document.body.style.position = "fixed";
        // };

        window.addEventListener('scroll', this.checkScroll);
    },
    mounted() {
        // this.removeWidthProperty();
        
        let toParam = this.$route.query.to;
        if (toParam) {
            toParam = decodeURIComponent(toParam);
            if (toParam.includes('-')) {
                // use original parameter value
            } else {
                // use empty string as parameter value
                toParam = '';
            }
        } else {
            toParam = '';
        }
        
        this.$api.get('/' + this.$route.params.slug, {
            params: {
                to: toParam,
                template: window.location.origin + "/"
            }
        })
        .then(response => {
            if (!response.data.data) {
                this.$router.push({ name: 'PageNotFound' });
                return;
            }
            var currentUrl = window.location.href;
            this.client = response.data.data;

            if (response.data.data.hajat === 'wanita') {
                document.title = "Wedding Invitation " + response.data.data.nama_wanita + " & " + response.data.data.nama_pria + " by It'sYourDay";
            }else {
                document.title = "Wedding Invitation " + response.data.data.nama_pria + " & " + response.data.data.nama_wanita + " by It'sYourDay"; 
            }

            this.gallery = response.data.data.gallery;
            this.pages = this.gallery.length;
            this.img1 = response.data.data.gallery[0];
            this.img2 = response.data.data.gallery[1];
            this.img3 = response.data.data.gallery[2];
            this.img4 = response.data.data.gallery[3];
            if (currentUrl.includes(this.client.template) == false) {
                this.$router.push({ name: 'PageNotFound' });
            }
            this.guest = response.data.data;
            this.audio = response.data.data.song;
            this.date = moment(response.data.data.tgl_akad).format('MMMM DD, YYYY hh:mm:ss a');
            if (response.data.data.config[0].countdown == 1) {
                this.date = moment(response.data.data.tgl_resepsi).format('MMMM DD, YYYY hh:mm:ss a');
            }else{
                this.date = moment(response.data.data.tgl_akad).format('MMMM DD, YYYY hh:mm:ss a');
            }
            this.config = response.data.data.config[0];
            this.story.title1 = response.data.data.story[0];
            this.story.description1 = response.data.data.story[0];
            this.story.date1 = response.data.data.story[0];
            this.story.month1 = response.data.data.story[0];
            this.story.year1 = response.data.data.story[0];
            this.story.media1 = response.data.data.story[0];
            this.story.type1 = response.data.data.story[0];
            this.story.order1 = response.data.data.story[0];
            this.story.rangeTime1 = response.data.data.story[0];
            this.story.title2 = response.data.data.story[1];
            this.story.description2 = response.data.data.story[1];
            this.story.date2 = response.data.data.story[1];
            this.story.month2 = response.data.data.story[1];
            this.story.year2 = response.data.data.story[1];
            this.story.media2 = response.data.data.story[1];
            this.story.type2 = response.data.data.story[1];
            this.story.order2 = response.data.data.story[1];
            this.story.rangeTime2 = response.data.data.story[1];
            this.story.title3 = response.data.data.story[2];
            this.story.description3 = response.data.data.story[2];
            this.story.date3 = response.data.data.story[2];
            this.story.month3 = response.data.data.story[2];
            this.story.year3 = response.data.data.story[2];
            this.story.media3 = response.data.data.story[2];
            this.story.type3 = response.data.data.story[2];
            this.story.order3 = response.data.data.story[2];
            this.story.rangeTime3 = response.data.data.story[2];
            this.story.title4 = response.data.data.story[3];
            this.story.description4 = response.data.data.story[3];
            this.story.date4 = response.data.data.story[3];
            this.story.month4 = response.data.data.story[3];
            this.story.year4 = response.data.data.story[3];
            this.story.media4 = response.data.data.story[3];
            this.story.type4 = response.data.data.story[3];
            this.story.order4 = response.data.data.story[3];
            this.story.rangeTime4 = response.data.data.story[3];
            this.story.title5 = response.data.data.story[4];
            this.story.description5 = response.data.data.story[4];
            this.story.date5 = response.data.data.story[4];
            this.story.month5 = response.data.data.story[4];
            this.story.year5 = response.data.data.story[4];
            this.story.media5 = response.data.data.story[4];
            this.story.type5 = response.data.data.story[4];
            this.story.order5 = response.data.data.story[4];
            this.story.rangeTime5 = response.data.data.story[4];
            this.submit.client_id = this.client.id;
            // Update hasNext property based on whether gallery has items
            this.hasNext = this.gallery.length > 1;
            this.alsoInvite = response.data.data.also_invite;
            this.timer_loop();
            this.$api.get('comment/' + this.client.id)
                .then(response => {
                    this.hopes = response.data.data;
                })
        })
        .catch(error => {
            // this.$router.push({ name: 'PageNotFound' });
            console.log(error.response.data);
        });

        this.$refs.horizontal.scrollToIndex(0);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.checkScroll);
    },
    methods: {
        // getDescGroomStyle() {
        //     if (window.innerWidth > 768 && this.config.profile_picture === 1) {
        //         // Cari elemen dengan class "elementor-column.elementor-col-50"
        //         const columnElement = document.querySelector('.elementor-column.elementor-col-50');
                
        //         if (columnElement) {
        //             columnElement.style.removeProperty('width');
        //         }
        //     } else {
        //         return {}; // Style default yang didefinisikan dalam media query
        //     }
        // },

        // removeWidthProperty() {
        //     if (window.innerWidth > 768 && this.config.profile_picture === 1) {
        //         const element = this.$refs.elementToModify;

        //         // Menghapus properti "width" dari style element
        //         if (element) {
        //         element.style.removeProperty('width');
        //         }
        //     }
        // },
        

        checkScroll() {
            const galleryElement = document.querySelector('.elementor-element-a26b801');
            if (galleryElement) {
                const rect = galleryElement.getBoundingClientRect();
                const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
                this.showGallery = isVisible;
            }
        },
        // closeModal() {
        //     this.isModalVisible = false;
        //     var a = this.$refs.audioElm;
        //     a.play();
        //     this.playing = true;

        //     if (this.isModalVisible == false) {
        //         document.body.style.position = null;
        //         document.body.style.overflow = "auto";
        //     }
        // },
        play: function (event) {
            var a = this.$refs.audioElm;
            if (a.paused) {
                a.play();
                this.playing = true;
            } else {
                a.pause();
                this.playing = false;
            }
        },
        timer_loop() {
            this.count++;
            this.now = Math.trunc(new Date().getTime() / 1000);
            if (this.count < 200 && this.modifiedDate > this.now) {
            setTimeout(this.timer_loop, 1000);
            } else {
            this.count = 0;
            }
        },
        post() {
            this.$api.post('comment', this.submit)
                .then(response => {
                    this.success = response.data.success
                    this.submit.name = '';
                    this.submit.comment = '';
                    this.submit.confirmation = '';
                    this.submit.request_songs = '';
                    this.submit.artist = '';
                    this.snackbar = true;
                    setTimeout(()=>{ this.snackbar=false; }, 3000);
                    this.$api.get('comment/' + this.client.id)
                        .then(response => {
                            this.hopes = response.data.data;
                        })
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        this.errors = error.response.data.errors;
                    }
                });
        },
        prev() {
            this.$refs.horizontal.prev()
        },
        next() {
            if (!this.hasNext && this.hasPrev) {
                this.$refs.horizontal.scrollToIndex(0);
                return;
            }

            if (this.hasNext) {
                this.$refs.horizontal.next();
            }
        },
        onScroll({ hasPrev, hasNext }) {
            this.hasPrev = hasPrev
            this.hasNext = hasNext
        },

        myFunction() {
            const copyText = document.getElementById("myInput");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);

            this.isCopied = true; // Atur variabel isCopied sebagai true setelah menyalin

            setTimeout(() => {
                this.isCopied = false; // Reset variabel isCopied setelah beberapa saat
            }, 2000); // Ganti 2000 dengan durasi waktu yang diinginkan (dalam milidetik)
        },

        myFunction2() {
            /* Get the text field */
            var copyText = document.getElementById("myInput2");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);

            this.isCopied2 = true; // Atur variabel isCopied sebagai true setelah menyalin

            setTimeout(() => {
                this.isCopied2 = false; // Reset variabel isCopied setelah beberapa saat
            }, 2000); // Ganti 2000 dengan durasi waktu yang diinginkan (dalam milidetik)
        },

        myFunction3() {
            /* Get the text field */
            var copyText = document.getElementById("myInput3");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);

            this.isCopied3 = true; // Atur variabel isCopied sebagai true setelah menyalin

            setTimeout(() => {
                this.isCopied3 = false; // Reset variabel isCopied setelah beberapa saat
            }, 2000); // Ganti 2000 dengan durasi waktu yang diinginkan (dalam milidetik)
        },

        myFunction4() {
            /* Get the text field */
            var copyText = document.getElementById("myInput4");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);

            this.isCopied4 = true; // Atur variabel isCopied sebagai true setelah menyalin

            setTimeout(() => {
                this.isCopied4 = false; // Reset variabel isCopied setelah beberapa saat
            }, 2000); // Ganti 2000 dengan durasi waktu yang diinginkan (dalam milidetik)
        },
        myFunction5() {
            /* Get the text field */
            var copyText = document.getElementById("myInput5");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);

            this.isCopied5 = true; // Atur variabel isCopied sebagai true setelah menyalin

            setTimeout(() => {
                this.isCopied5 = false; // Reset variabel isCopied setelah beberapa saat
            }, 2000); // Ganti 2000 dengan durasi waktu yang diinginkan (dalam milidetik)
        },
        generateCalendarLink() {
            const linkText = 'Pernikahan ' + this.client.nama_wanita + ' dan ' + this.client.nama_pria;
            const startDate = moment(this.client.tgl_resepsi).format('YYYYMMDD');
            const startTime = moment(this.client.tgl_resepsi).format('HHmmss');
            const endTime = moment(this.client.tgl_resepsi_selesai).format('HHmmss');

            return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(linkText)}&details&dates=${startDate}T${startTime}/${startDate}T${endTime}&location`;
        },

        getElapsedTime(created_at) {
            const currentTime = Math.floor(Date.now() / 1000); // Waktu saat ini dalam detik
            const createdAt = Math.floor(new Date(created_at).getTime() / 1000); // Waktu yang diberikan dalam detik

            const timeDifference = currentTime - createdAt;

            if (this.config.language === 1) {
                if (timeDifference < 60) {
                return 'A few seconds ago';
                } else if (timeDifference < 3600) {
                const minutes = Math.floor(timeDifference / 60);
                return `${minutes} minutes ago`;
                } else if (timeDifference < 86400) {
                const hours = Math.floor(timeDifference / 3600);
                const remainingMinutes = Math.floor((timeDifference % 3600) / 60);
                return `${hours} hours, ${remainingMinutes} minutes ago`;
                } else {
                const days = Math.floor(timeDifference / 86400);
                return `${days} days ago`;
                }
            } else {
                if (timeDifference < 60) {
                return 'Beberapa detik yang lalu';
                } else if (timeDifference < 3600) {
                const minutes = Math.floor(timeDifference / 60);
                return `${minutes} menit yang lalu`;
                } else if (timeDifference < 86400) {
                const hours = Math.floor(timeDifference / 3600);
                const remainingMinutes = Math.floor((timeDifference % 3600) / 60);
                return `${hours} jam, ${remainingMinutes} menit yang lalu`;
                } else {
                const days = Math.floor(timeDifference / 86400);
                return `${days} hari yang lalu`;
                }
            }
        },


        getFirstCharacter(text) {
            return text ? text.charAt(0) : '';
        },
    },
    computed: {
        seconds() {
            const result = (this.modifiedDate - this.now) % 60;
            return result < 0 ? 0 : result;
        },
        minutes() {
            const result = Math.trunc((this.modifiedDate - this.now) / 60) % 60;
            return result < 0 ? 0 : result;
        },
        hours() {
            const result = Math.trunc((this.modifiedDate - this.now) / 60 / 60) % 24;
            return result < 0 ? 0 : result;
        },
        days() {
            const result = Math.trunc((this.modifiedDate - this.now) / 60 / 60 / 24);
            return result < 0 ? 0 : result;
        },
        modifiedDate: function () {
            return Math.trunc(Date.parse(this.date) / 1000)
        },
        decodedValue() {
            let toParam = this.$route.query.to;
            if (toParam) {
                toParam = decodeURIComponent(toParam);
                if (toParam.includes('-')) {
                return '';
                } else {
                return toParam;
                }
            } else {
                return '';
            }
        },
        showName() {
            let toParam = this.$route.query.to;
            if (toParam) {
                toParam = decodeURIComponent(toParam);
                return !toParam.includes('-');
            } else {
                return false;
            }
        },
         plainAddress() {
            // Menghapus tag HTML dari alamat hadiah sebelum menampilkannya
            if (this.client.gift_address) {
                return this.client.gift_address.replace(/<\/?[^>]+(>|$)/g, '');
            }
            return '';
        },
        buttonText() {
            return this.config.language === 1
                ? this.isCopied ? 'Copied' : 'Copy Account Number'
                : this.isCopied ? 'Tersalin' : 'Salin No. Rek';
        },
        buttonText2() {
            return this.config.language === 1
                ? this.isCopied2 ? 'Copied' : 'Copy Account Number'
                : this.isCopied2 ? 'Tersalin' : 'Salin No. Rek';
        },
        buttonText3() {
            return this.config.language === 1
                ? this.isCopied3 ? 'Copied' : 'Copy Account Number'
                : this.isCopied3 ? 'Tersalin' : 'Salin No. Rek';
        },
        buttonText4() {
            return this.config.language === 1
                ? this.isCopied4 ? 'Copied' : 'Copy Account Number'
                : this.isCopied4 ? 'Tersalin' : 'Salin No. Rek';
        },
        buttonText5() {
            return this.config.language === 1
                ? this.isCopied5 ? 'Copied' : 'Copy Address'
                : this.isCopied5 ? 'Tersalin' : 'Salin Alamat';
        }
        
    },
    watch: {
        gallery() {
            this.pages = this.gallery.length;
        },

        // tambahan otomatis gallery
        showGallery(newValue) {
            if (newValue) {
                this.intervalId = setInterval(this.next, 3000); // Memanggil this.next tanpa ()
            } else {
                clearInterval(this.intervalId); // Membersihkan interval jika showGallery berhenti
            }
        }
    }
}
</script>


<!-- Pagination CSS -->
<style scoped>
/* snackbar */
#snackbar {
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 49%;
  bottom: 30px;
  border-color: #333;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  #snackbar {
    min-width: 200px;
    margin-left: -100px;
    left: 37%;
  }
}

.slide-fade-enter-active {
  transition: all .8s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
}
/*  */
header .pagination {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav>button {
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    margin: 0.25rem;
}

nav>button.active {
    color: #ffffff;
    border-color: #ffffff;
    background-color: #74b49b;
}

nav>button.inactive {
    color: #ffffff;
    border-color: #ffffff;
    background-color: #a7d7c5;
}

nav>button:focus {
    outline: none;
}

nav>button>svg {
    height: 1.5rem;
    width: 1.5rem;
    fill: currentColor;
}

.icon-bar button {
    /* display: block; */
    /* text-align: center; */
    padding: 8px;
    transition: all 0.3s ease;
    color: #ffffff;
    height: 40px;
    width: 40px;
    font-size: 15px;
    background: #162447;
    border-radius: 50%;
    border: 1px solid #162447;
}

</style>